import {
    Component,
    ElementRef,
    Input,
    OnChanges,
    OnInit,
    ViewChild,
    ViewContainerRef
} from '@angular/core';
import {ActivatedRoute, Router} from '@angular/router';
import {AffairStep} from '@src/app/model/affair-step.model';
import {Affair} from '@src/app/model/affair.model';
import {Dossier, DOSSIER_STATUS} from '@src/app/model/dossier.model';
import {ActionBottomSheetService} from '@src/app/services/action-bottom-sheet.service';
import {ApiService} from '@src/app/services/api.service';
import {ModalService} from '@src/app/services/modal.service';
import {ScrollViewService} from '@src/app/services/scroll-view.service';
import {ToastService} from '@src/app/services/toast.service';
import {takeUntil} from 'rxjs/operators';
import {ActionStateResult, SimpleChanges} from '@src/app/shared/interfaces';
import {CdkDragDrop} from '@angular/cdk/drag-drop';
import {BaseComponent} from '@src/app/shared/base.component';
import {DossierService} from '@src/app/services/dossier.service';

@Component({
    selector: 'app-step-etape',
    templateUrl: './step-etape.component.html',
    styleUrls: ['./step-etape.component.scss']
})
export class StepEtapeComponent extends BaseComponent implements OnInit, OnChanges {

    @Input() isVisible: boolean;
    @Input() viewContainerRef: ViewContainerRef;
    affair: Affair;
    affairId: number;
    isBusy: boolean;
    isDossierBusy: any = {};
    activeStep: any;
    activeStatut = 1;
    statusLabel = DOSSIER_STATUS;

    @ViewChild('scrollStep') stepScrollView: ElementRef;

    constructor(
        private scrollViewService: ScrollViewService,
        private apiService: ApiService,
        private router: Router,
        private activatedRoute: ActivatedRoute,
        private toggleBottomSheetService: ActionBottomSheetService,
        private toastService: ToastService,
        private modalService: ModalService,
        private dossierService: DossierService,
    ) {
        super();
    }

    ngOnInit(): void {
        this.isBusy = true;
        this.activatedRoute.queryParams.subscribe((params) => {
            if (params['_s']) { this.activeStep = params['_s']; }
        });
        this.activatedRoute.params.subscribe((params) => {
            this.affairId = parseInt(params['id'], 10);
            if (this.isVisible) { this.getAffair(); }
        });
    }

    ngOnChanges(changes: SimpleChanges) {
        if (
            changes.isVisible.currentValue
            && !changes.isVisible.previousValue
            && changes.isVisible.previousValue !== undefined
        ) {
            this.isBusy = true;
            this.getAffair();
        }
    }

    getAffair(): void {
        this.apiService.getAffairSteps(this.affairId).subscribe((affair) => {
            for (const [i, affairStep] of affair.affairSteps.entries()) {
                if (affairStep.active === 1 && !this.activeStep) { this.activeStep = i; }
            }
            this.affair = affair;
            this.isBusy = false;
        });
    }

    goToStep(id: number) {
        if (this.activeStep !== id) {
            this.activeStep = id;
            this.scrollViewService.centerOnSelected(this.stepScrollView, id);
            if (Array.isArray(this.affair.affairSteps[id].states)) {
                this.isBusy = true;
                this.apiService.getAffairStep(this.affairId, id + 1).subscribe((affairStep: AffairStep) => {
                    this.affair.affairSteps[id] = affairStep;
                    this.isBusy = false;
                }, () => this.isBusy = false);
            }
        }
    }

    goToStatut(id: number) {
        this.activeStatut = id;
    }

    // TODO affaire service
    onTapStepValid(step: AffairStep) {
        this.apiService.updateStateStepAffair(step.id, 1).subscribe((affair) => {
            this.toastService.show(`L'étape "${step.step.label}" a bien été validée`);
            for (const [i, affairStep] of affair.affairSteps.entries()) {
                if (affairStep.active === 1) { this.activeStep = i; }
            }
            this.affair = affair;
        });
    }

    // TODO affaire service
    onTapStepRefuse(step: AffairStep) {
        this.apiService.updateStateStepAffair(step.id, 0).subscribe((affair) => {
            this.toastService.show(`L'étape "${step.step.label}" a bien été annulée`);
            for (const [i, affairStep] of affair.affairSteps.entries()) {
                if (affairStep.active === 1) { this.activeStep = i; }
            }
            this.affair = affair;
        });
    }

    // TODO affaire service
    onTapMoreStep(affairStep: AffairStep): void {
        this.toggleBottomSheetService.showBottomSheet(
            'step',
            affairStep,
            {},
            this.viewContainerRef
        ).subscribe(result => {
            if (result && result.action) { this[result.action](affairStep); }
        });
    }

    onTapAddDossier(state: number): void {
        const dossier = new Dossier();
        dossier.isChargeAffair = this.affair.isChargeAffair;
        dossier.state = state;
        dossier.affair = {id: this.affair.id};
        dossier.step = {id: this.affair.affairSteps[this.activeStep].step.id};

        this.dossierService.add(dossier, false, this.viewContainerRef)
            .pipe(takeUntil(this.notifier))
            .subscribe((result: Dossier) => {
                if (result) {
                    this.affair.affairSteps[this.activeStep].states[state].push(result);
                }
            });
    }

    drop(event: CdkDragDrop<any>) {
        if (event.previousContainer !== event.container) {
            const dossier: Dossier = <Dossier>event.item.data;
            if (dossier.code === 'CHIFFRAGE_1' && !this.affair.isChargeAffair) {
                return false;
            }
            const previous = this.affair.affairSteps[this.activeStep].states[event.previousContainer.data];
            this.affair.affairSteps[this.activeStep].states[event.previousContainer.data] = previous.filter(d => d.id !== dossier.id);
            this.affair.affairSteps[this.activeStep].states[event.container.data].push(dossier);
            this.isDossierBusy[dossier.id] = event.container.data;
        }
    }

    affairUpdateHandle(value: {result: ActionStateResult, dossier: Dossier}) {
        delete this.isDossierBusy[value.dossier.id];
        this.affair.affairSteps[this.activeStep].avancement = value.result.avancement;
    }

    sortByDossier(statut: number) {
        const prop1 = 'specificList';
        const prop2 = 'label';
        const dossiers = this.affair.affairSteps[this.activeStep].states[statut] || [];
        if (dossiers.length) {
            return dossiers.sort((a, b) => {
                const keyA1 = a[prop1];
                const keyA2 = a[prop2].toLowerCase();
                const keyB1 = b[prop1];
                const keyB2 = b[prop2].toLowerCase();

                if (!keyA1 && keyB1) { return 1; }
                if (keyA1 && !keyB1) { return -1; }
                if (keyA2 < keyB2) { return -1; }
                if (keyA2 > keyB2) { return 1; }
                return 0;
            });
        }
        return dossiers;
    }

}
