import {Component, Input, OnChanges, OnInit, ViewContainerRef} from '@angular/core';
import {Router} from '@angular/router';
import {ApiService} from '@src/app/services/api.service';
import {ToastService} from '@src/app/services/toast.service';
import {AlertService} from '@src/app/services/alert.service';
import {Trade} from '@src/app/model/trade.model';
import {ActionBottomSheetService} from '@src/app/services/action-bottom-sheet.service';
import {ModalService} from '@src/app/services/modal.service';
import { SimpleChanges } from '@src/app/shared/interfaces';

@Component({
    selector: 'app-annuaire-trade',
    templateUrl: './annuaire-trade.component.html',
    styleUrls: ['./annuaire-trade.component.scss'],
    providers: [ActionBottomSheetService]
})
export class AnnuaireTradeComponent implements OnInit {

    @Input() isVisible: boolean;
    @Input() viewContainerRef: ViewContainerRef;
    title = 'Corps d\'états';
    mode = 'over';
    backdrop = true;
    trades: Trade[];
    tradesList: Trade[];
    isBusy: boolean;
    search: string;

    constructor(
        private router: Router,
        private apiService: ApiService,
        private toastService: ToastService,
        private alertService: AlertService,
        private toggleBottomSheetService: ActionBottomSheetService,
        private modalService: ModalService,
    ) { }

    ngOnInit(): void {
        // this.isBusy = true;

       this.getTrade();
    }

    // ngOnChanges(changes: SimpleChanges) {
        // if (
        //     changes.isVisible.currentValue
        //     && !changes.isVisible.previousValue
        //     && typeof changes.isVisible.previousValue !== 'undefined'
        // ) {
        //     this.getTrade();
        // }
    // }

    getTrade() {
        this.apiService.getTrades().subscribe((trades) => {
            this.tradesList = trades.trades;
            this.trades = trades.trades;
            // this.isBusy = false;
        });
    }

    onSearch() {
        this.trades = this.tradesList.filter((trade) => {
            return trade.label.toLowerCase().indexOf(this.search.toLowerCase()) >= 0;
        });
    }

    clearSearch() {
        this.search = '';
        this.trades = this.tradesList;
    }

    onTapAdd() {
        this.router.navigate(['annuaire', 'trade', 'edit']);
    }

    onTapAddModal() {
        this.modalService.show(
            {
                type: 'trade-add'
            },
            'base-modal',
            this.viewContainerRef,
            true
        ).subscribe((result) => {
            if (result) {
                this.trades.unshift(result);
            }
        });
    }

    onTapEdit(trade: Trade) {
        this.router.navigate(['annuaire', 'trade', 'edit', trade.id]);
    }

    onTapEditModal(trade: Trade) {
        this.modalService.show(
            {
                id: trade.id,
                type: 'trade-edit',
            },
            'base-modal',
            this.viewContainerRef,
            true
        ).subscribe((result) => {
            if (result) {
                this.trades = this.trades.map(t => t.id === result.id ? result : t);
            }
        });
    }

    onTapDelete(trade: Trade) {
        const text = `Vous allez définitivement supprimer un corps d'état.
                Vous ne pourrez pas récupérer ce corps d'état !`;
        this.alertService.show(text).then((result) => {
            if (result.isConfirmed) {
                this.apiService.deleteTrade(trade.id).subscribe(() => {
                    this.toastService.show(`Le corps d\'état "${trade.label}" a bien été supprimer.`);
                    this.trades = this.trades.filter(t => t.id !== trade.id);
                });
            }
        });
    }

    onTapMore(trade: Trade) {
        this.toggleBottomSheetService.showBottomSheet(
            'trade',
            trade,
            {},
            this.viewContainerRef
        ).subscribe(result => {
            if (result && result.action) {
                setTimeout(() => {
                    switch (result.action) {
                        case 'onTapEdit':
                            this.onTapEditModal(trade);
                            break;
                        case 'onTapDelete':
                            this.onTapDelete(trade);
                            break;
                    }
                }, 300);
            }
        });
    }

    trackByFn(index, item) {
        return index;
    }
}
