import {Component, EventEmitter, Input, OnDestroy, OnInit, Output, ViewContainerRef} from '@angular/core';
import {Dossier, DossierSpecificList, USER_TYPES} from '@src/app/model/dossier.model';
import {DossierService} from '@src/app/services/dossier.service';
import {Subject} from 'rxjs';
import {BridgeModalStorage, ActionVersionResult, ActionResult} from '@src/app/shared/interfaces';
import {Router} from '@angular/router';
import {takeUntil} from 'rxjs/operators';

@Component({
  selector: 'app-specific-item',
  templateUrl: './specific-item.component.html',
  styleUrls: ['./specific-item.component.scss']
})
export class SpecificItemComponent implements OnInit, OnDestroy {

  @Input() viewContainerRef: ViewContainerRef;
  @Input() specific: DossierSpecificList;
  @Input() dossier: Dossier;
  @Output() deleteEmitter = new EventEmitter<DossierSpecificList>();
  notifier = new Subject();
  userTypes = USER_TYPES;
  isActionBusy: boolean;

  constructor(
      private router: Router,
      private dossierService: DossierService,
  ) { }

  ngOnInit(): void {
    this.isActionBusy = false;
  }

  ngOnDestroy(): void {
    this.notifier.next();
    this.notifier.complete();
  }

  onTapEdit(dossier: DossierSpecificList): void {
    this.dossierService.edit(dossier, true, this.viewContainerRef).subscribe((result: Dossier) => {
      if (result) { this.dossier.specificList = result.specificList; }
    });
  }

  // TODO On peux utilisé onTapEditDossier partout, mais refaire le cheminement de modal coté appli
  // onTapEditDossierModal(dossier: Dossier): void {
  //   this.modalService.show(
  //       {id: dossier.id, type: 'dossier-edit'},
  //       'base-modal',
  //       this.viewContainerRef,
  //       true
  //   )
  //       .pipe(takeUntil(this.notifier))
  //       .subscribe((result) => {
  //         if (result) { this.dossier.specificList = result.specificList; }
  //       });
  // }

  onTapAuthorize(dossier: DossierSpecificList|Dossier, parentDossier: Dossier = null, bridge: BridgeModalStorage = null): void {
    this.dossierService.authorize(dossier, parentDossier, true, true, this.viewContainerRef)
        .subscribe((result: Dossier|BridgeModalStorage) => {
          if (result && result instanceof Dossier) {
            this.dossier.specificList = result.specificList;
          }
        });
  }

  // TODO On peux utilisé onTapAuthorizeDossier partout, mais refaire le cheminement de modal coté appli
  // onTapAuthorizeDossierModal(dossier: DossierSpecificList|Dossier): void {
  //   this.modalService.show(
  //       {id: dossier.id, type: 'dossier-societies'},
  //       'base-modal',
  //       this.viewContainerRef,
  //       true
  //   )
  //       .pipe(takeUntil(this.notifier))
  //       .subscribe((result) => {
  //         if (result) { this.dossier.specificList = result.specificList; }
  //       });
  // }

  onTapVersion(specific: DossierSpecificList): void {
    this.isActionBusy = true;
    this.dossierService.version(specific)
        .pipe(takeUntil(this.notifier))
        .subscribe((result: ActionVersionResult) => {
          if (result) { this.router.navigate(['/specific-detail', result.version.id], {queryParams: {'_a': 1}}); }
        });
  }

  onTapDelete(specific: DossierSpecificList): void {
    this.isActionBusy = true;
    this.dossierService.delete(specific).subscribe((result: ActionResult) => {
      if (result.success) { this.deleteEmitter.emit(specific); }
      this.isActionBusy = false;
    });
  }

  onTapMoreVersion(): void {
    this.dossierService.moreSpecific(this.specific, 'version', this.viewContainerRef)
        .pipe(takeUntil(this.notifier))
        .subscribe(result => {
          if (result && result.action) {
            setTimeout(() => {
              console.log(result);
              this.router.navigate(['/specific-detail', result.action]);
            }, 300);
          }
        });
  }

  onTapMoreAction(): void {
    this.dossierService.moreSpecific(this.specific, 'specific', this.viewContainerRef)
        .pipe(takeUntil(this.notifier))
        .subscribe(result => {
          if (result && result.action) {
            setTimeout(() => {
              console.log(result);
              this[result.action](this.specific);
            }, 300);
          }
        });
  }

  // TODO mettre default trackBy dans Utils
  trackBy(index, item): any {
    return item.id;
  }

}
