<h1 mat-dialog-title>
    <span class="d-inline-block mt-1">{{title}}</span>
    <button (click)="onClose()" mat-icon-button color="warn" class="float-right ml-3">
        <mat-icon>close</mat-icon>
    </button>
</h1>
<div mat-dialog-content>
    <div *ngIf="isBusy" class="d-flex justify-content-center align-items-center p-5">
        <mat-spinner></mat-spinner>
    </div>
    <div *ngIf="!isBusy">
        <form [formGroup]="userForm">
            <div class="row">
                <div class="col-md-6">
                    <mat-form-field appearance="outline" class="input-full-width">
                        <mat-label>Prénom</mat-label>
                        <input matInput type="text" formControlName="name" required>
                    </mat-form-field>
                </div>
                <div class="col-md-6">
                    <mat-form-field appearance="outline" class="input-full-width">
                        <mat-label>Nom de famille</mat-label>
                        <input matInput type="text" formControlName="surname" required>
                    </mat-form-field>
                </div>

                <div class="col-md-6">
                    <mat-form-field appearance="outline" class="input-full-width">
                        <mat-label>Email</mat-label>
                        <input matInput type="email" formControlName="email" required pattern="[a-z0-9._%+-]+@[a-z0-9.-]+\.[a-z]{2,4}$">
                    </mat-form-field>
                </div>

                <div class="col-md-6">
                    <mat-form-field appearance="outline" class="input-full-width">
                        <mat-label>Téléphone</mat-label>
                        <input matInput type="text" formControlName="phone">
                    </mat-form-field>
                </div>
            </div>
        </form>
    </div>
</div>
<div *ngIf="!isBusy" mat-dialog-actions class="justify-content-end pb-3">
    <button [disabled]="isSaving" mat-raised-button color="primary" type="submit" (click)="onSubmit()">
        <mat-spinner *ngIf="isSaving" class="btn-spinner btn-spinner-white"></mat-spinner>
        <mat-icon *ngIf="!isSaving">save</mat-icon>
        Valider
    </button>
</div>
