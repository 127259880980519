import {Component, OnInit, ViewContainerRef} from '@angular/core';
import {ActivatedRoute, Router} from '@angular/router';
import {Dossier, DossierSpecificList, DOSSIER_STATUS} from '@src/app/model/dossier.model';
import {takeUntil} from 'rxjs/operators';
import {DossierService} from '@src/app/services/dossier.service';
import {ActionStateResult, AffairNavItems, BridgeModalStorage} from '@src/app/shared/interfaces';
import {BaseComponent} from '@src/app/shared/base.component';
import {MatDrawerMode} from '@angular/material/sidenav';
import {UploadImageService} from '@src/app/services/upload-image.service';
import {DocumentService} from '@src/app/services/document.service';
import {ActionBottomSheetService} from '@src/app/services/action-bottom-sheet.service';

@Component({
    selector: 'app-specific',
    templateUrl: './specific.component.html',
    styleUrls: ['./specific.component.scss'],
    providers: [ActionBottomSheetService, UploadImageService],
})
export class SpecificComponent extends BaseComponent implements OnInit {

    title = 'Chargement...';
    mode: MatDrawerMode = 'over';
    backdrop = true;
    dossier: Dossier;
    isBusy: boolean;
    statusLabel = DOSSIER_STATUS;
    spinner = {allDownload: false};
    back: any[];
    querySearch: any;
    items: AffairNavItems = {
        back: true,
        state: true,
        views: false,
        collaboratif: true,
        equipe: true,
    };

    constructor(
        public viewContainerRef: ViewContainerRef,
        private router: Router,
        private activatedRoute: ActivatedRoute,
        private dossierService: DossierService,
        private uploadImageService: UploadImageService,
        private documentService: DocumentService,
    ) {
        super();
        this.sharedService.updateTitle(this.title);
        this.sharedService.updateMode(this.mode);
        this.sharedService.updateBackdrop(this.backdrop);
    }

    ngOnInit(): void {
        this.isBusy = true;
        this.activatedRoute.params
            .pipe(takeUntil(this.notifier))
            .subscribe(params => {
                this.dossierService.get(params['id'])
                    .pipe(takeUntil(this.notifier))
                    .subscribe((dossier) => {
                    this.dossier = dossier;
                    this.sharedService.updateTitle(this.dossier.affair.label + ' - ' + this.dossier.label);
                    this.title = this.dossier.affair.label;
                    this.back = ['/affaire', this.dossier.affair.id];
                    this.querySearch = {
                        affair: this.dossier.affair.id,
                        step: this.dossier.step.id,
                        dirid: this.dossier.id,
                        dirName: this.dossier.label,
                    };
                    this.isBusy = false;
                });
            });
    }

    onTapAddDossier(dossier: Dossier): void {
        this.dossierService.add(dossier, true, this.viewContainerRef).subscribe((result: Dossier) => {
            if (result) {
                setTimeout(() => {
                    this.onTapFileDossier(result);
                }, 600);
            }
        });
    }

    onTapAddDossierModal(dossier: Dossier): void {
        this.dossierService.add(dossier, true, this.viewContainerRef).subscribe((result: Dossier) => {
            if (result) {
                setTimeout(() => {
                    this.onTapFileDossierModal(result);
                }, 600);
            }
        });
    }

    onTapFileDossier(dossier: Dossier): void {
        this.dossierService.addFile(dossier, this.viewContainerRef).subscribe((result: Dossier) => {
            if (result) {
                setTimeout(() => {
                    this.onTapShorcutDossier(result);
                }, 300);
            }
        });
    }

    private uploadFile(dossier, event) {
        if (event.eventName === 'cancel') {
            this.onTapShorcutDossier(dossier);
        } else {
            this.documentService.waitingFile(dossier, event, 'upload', this.viewContainerRef)
                .pipe(takeUntil(this.notifier))
                .subscribe((result) => {
                    if (result) {
                        setTimeout(() => {
                            this.onTapShorcutDossier(dossier);
                        }, 600);
                    }
                });
        }
    }

    private onTapAddFile(dossier: Dossier) {
        this.uploadImageService.uploadFile('upload').then((event) => {
            setTimeout(() => {
                this.uploadFile(dossier, event);
            }, 600);
        });
    }

    private onTapAddMedia(dossier: Dossier) {
        this.uploadImageService.uploadMedia('upload').then((event) => {
            setTimeout(() => {
                this.uploadFile(dossier, event);
            }, 600);
        });
    }

    private onTapAddCamera(dossier: Dossier) {
        this.uploadImageService.uploadCamera('upload').then((event) => {
            setTimeout(() => {
                this.uploadFile(dossier, event);
            }, 600);
        });
    }

    onTapFileDossierModal(dossier: Dossier): void {
        this.documentService.moreSpecificFile(this.dossier, 'create-specific', this.viewContainerRef)
            .pipe(takeUntil(this.notifier))
            .subscribe(result => {
                if (result && result.action) {
                    setTimeout(() => {
                        this[result.action](dossier);
                    }, 600);
                }
            });
        // this.uploadImageService.uploadFile('upload').then((event) => {
        //     console.log('uploadFile', event);
        //     setTimeout(() => {
        //         if (event.eventName === 'cancel') {
        //             this.onTapShorcutDossier(dossier);
        //         } else {
        //             this.uploadFile(dossier, event);
        //         }
        //     }, 300);
        // });
    }

    onTapShorcutDossier(dossier: Dossier): void {
        this.dossierService.shortcut(dossier, true, this.viewContainerRef).subscribe((result: Dossier) => {
            if (result) {
                setTimeout(() => {
                    dossier.shortcuts = result.shortcuts;
                    this.onTapAuthorizeDossier(dossier, this.dossier);
                }, 600);
            }
        });
    }

    onTapAuthorizeDossier(dossier: DossierSpecificList|Dossier, parentDossier: Dossier = null, bridge: BridgeModalStorage = null): void {
        this.dossierService.authorize(dossier, parentDossier, true, false, this.viewContainerRef)
            .subscribe((result: Dossier|BridgeModalStorage) => {
                if (result && result instanceof Dossier) {
                    this.dossier.specificList = result.specificList;
                }
            });
    }

    // TODO On peux utilisé onTapAuthorizeDossier partout, mais refaire le cheminement de modal coté appli
    // onTapAuthorizeDossierModal(dossier: DossierSpecificList|Dossier): void {
    //     this.modalService.show(
    //         {id: dossier.id, type: 'dossier-societies'},
    //         'base-modal',
    //         this.viewContainerRef,
    //         true
    //     )
    //         .pipe(takeUntil(this.notifier))
    //         .subscribe((result) => {
    //             if (result) { this.dossier.specificList = result.specificList; }
    //         });
    // }

    onTapDoDossier(dossier: Dossier): void {
        this.dossierService.state(dossier, 1).subscribe((result: ActionStateResult) => {
            if (result) { this.dossier.state = 1; }
        });
    }

    onTapWorkDossier(dossier: Dossier): void {
        this.dossierService.state(dossier, 2).subscribe((result: ActionStateResult) => {
            if (result) { this.dossier.state = 2; }
        });
    }

    onTapValidDossier(dossier: Dossier): void {
        this.dossierService.state(dossier, 3).subscribe((result: ActionStateResult) => {
            if (result) { this.dossier.state = 3; }
        });
    }

    // both but commented on app
    onTapDownloadAllDocument(): void {
        this.spinner.allDownload = true;
        this.dossierService.download(this.dossier).then(() => {
            this.spinner.allDownload = false;
        }, () => this.spinner.allDownload = false);
    }

    specificDeleteHandler(specific: DossierSpecificList): void {
        this.dossier.specificList = this.dossier.specificList.filter(s => s.id !== specific.id);
    }
}
