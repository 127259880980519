import { Component, EventEmitter, Input, OnInit, Output, ViewChild } from '@angular/core';
import { FormControl, FormGroup } from '@angular/forms';
import { MatInput } from '@angular/material/input';
import { MatSelect } from '@angular/material/select';
import { Affair, STATES, STEPS } from '@src/app/model/affair.model';
import { Dossier } from '@src/app/model/dossier.model';
import { User } from '@src/app/model/user.model';
import { ApiService } from '@src/app/services/api.service';
import { UsersResult } from '@src/app/shared/interfaces';
import {Observable, Subject} from 'rxjs';
import {map} from 'rxjs/operators';
import {StorageService} from '@src/app/services/storage.service';

@Component({
    selector: 'app-affair-filter',
    templateUrl: './affair-filter.component.html',
    styleUrls: ['./affair-filter.component.scss']
})
export class AffairFilterComponent implements OnInit {

    @ViewChild('search') inputName: MatInput;
    @ViewChild('state') selectState: MatSelect;
    @ViewChild('step') selectStep: MatSelect;
    @ViewChild('user') selectUser: MatSelect;
    @Input() affair: Affair;
    @Input() affairs: Affair[];
    @Output() affairsFiltred = new EventEmitter<Observable<Affair[]>>();
    open = true;
    notifier = new Subject();
    isBusy: boolean;
    dossier: Dossier;
    optionsStates = STATES;
    optionsSteps = STEPS;
    optionsUsers$: Observable<User[]>;
    filterForm: FormGroup;
    roles: string[] = [];

    constructor(
        private apiService: ApiService,
        private storageService: StorageService,
    ) { }

    ngOnInit(): void {
        this.isBusy = true;
        this.roles = this.storageService.roles;

        if (this.hasRoles(['ROLE_AFFAIRE', 'ROLE_ADMIN', 'ROLE_SUPER_ADMIN'])) {
            this.optionsUsers$ = this.apiService.getUsers().pipe(
                map((usersResult: UsersResult) => usersResult.users)
            );
        }

        this.filterForm = new FormGroup({
            search: new FormControl(),
            state: new FormControl(),
            step: new FormControl(),
            user: new FormControl(),
        });

        this.onChanges();

        const storageFilter = this.storageService.affairListFilter;
        if (storageFilter && (storageFilter.search || storageFilter.state || storageFilter.step || storageFilter.user)) {
            this.filterForm.patchValue(storageFilter);
        }
    }

    hasRoles(roles: string[]): boolean {
        return roles.some((role) => this.roles.includes(role));
    }

    toggle() {
        this.open = !this.open;
    }

    onChanges(): void {
        this.filterForm.valueChanges.subscribe(val => {
            this.storageService.affairListFilter = val;
            this.affairsFiltred.emit(this.apiService.getAffairsFilter({filter: val}));
        });
    }

    onReset(): void {
        this.filterForm.reset();
    }
}
