import {AfterContentChecked, Component, OnInit, ViewContainerRef} from '@angular/core';
import {Router} from '@angular/router';
import {DrawerService} from '@src/app/services/drawer.service';
import {AuthService} from '@src/app/services/auth.service';
import {VersionService} from '@src/app/services/version.service';
import {BaseComponent} from '@src/app/shared/base.component';
import {MatDrawerMode} from '@angular/material/sidenav';

@Component({
    selector: 'app-root',
    templateUrl: './app.component.html',
    styleUrls: ['./app.component.scss'],
})
export class AppComponent extends BaseComponent implements AfterContentChecked, OnInit {
    title: string;
    mode: MatDrawerMode = 'over';
    backdrop = true;
    connected: boolean;
    initiale: string;
    version = '0.0.0';

    constructor(
        private router: Router,
        public drawerService: DrawerService,
        private authService: AuthService,
        private versionService: VersionService,
        public viewContainerRef: ViewContainerRef,
    ) {
        super();
        this.sharedService.title.subscribe(title => this.title = title);
        this.sharedService.mode.subscribe(mode => this.mode = mode);
        this.sharedService.backdrop.subscribe(backdrop => this.backdrop = backdrop);
        this.versionService.getVersion().then(version => this.version = version);
    }

    ngOnInit() {
        // console.log('connected', this.storageService.getUser());
    }

    ngAfterContentChecked() {
        this.connected = !!this.storageService.token;
        this.initiale = this.storageService.initiale;
    }

    public onDisconnectTap() {
        this.connected = false;
        this.roles = [];
        // this.sharedService.updateConnected(false);
        this.authService.logout(true);
    }
}
