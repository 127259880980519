import {Component, Inject, OnInit} from '@angular/core';
import {Router} from '@angular/router';
import {MAT_DIALOG_DATA} from '@angular/material/dialog';

@Component({
    templateUrl: './base-modal-view.component.html',
})
export class BaseModalViewComponent implements OnInit {
    constructor(
        private router: Router,
        @Inject(MAT_DIALOG_DATA) public data: any,
    ) {
    }

    ngOnInit(): void {
        switch (this.data.type) {
            // case 'affair-add':
            //     this.router.navigate(['affair-form-modal'], {relativeTo: this.activatedRoute});
            //     break;
            // case 'affair-edit':
            //     this.router.navigate(['affair-form-modal', this.data.id], {relativeTo: this.activatedRoute});
            //     break;
            // case 'dossier-add':
            //     this.router.navigate(['dossier-form-modal'], {relativeTo: this.activatedRoute});
            //     break;
            // case 'dossier-edit':
            //     this.router.navigate(['dossier-form-modal', this.data.id], {relativeTo: this.activatedRoute});
            //     break;
            case 'dossier-societies':
                this.router.navigate(
                    [{ outlets: { modalContent: ['dossier-societies-form-modal', this.data.id] } }],
                    {
                        skipLocationChange: true
                    });
                break;
            // case 'society-add':
            //     this.router.navigate(['society-form-modal'], {relativeTo: this.activatedRoute});
            //     break;
            // case 'society-edit':
            //     this.router.navigate(['society-form-modal', this.data.id], {relativeTo: this.activatedRoute});
            //     break;
            // case 'trade-add':
            //     this.router.navigate(['trade-form-modal'], {relativeTo: this.activatedRoute});
            //     break;
            // case 'trade-edit':
            //     this.router.navigate(['trade-form-modal', this.data.id], {relativeTo: this.activatedRoute});
            //     break;
            case 'user-add':
                this.router.navigate(
                    [{ outlets: { modalContent: ['user-form-modal'] } }],
                    {
                        skipLocationChange: true
                    });
                break;
            case 'user-edit':
                this.router.navigate(
                    [{ outlets: { modalContent: ['user-form-modal', this.data.id] } }],
                    {
                        skipLocationChange: true
                    });
                break;
            case 'guest-add':
                this.router.navigate(
                    [{ outlets: { modalContent: ['guest-form-modal'] } }],
                    {
                        skipLocationChange: true
                    });
                break;
        }
    }
}
