<div fxFlex fxLayoutAlign="center center" *ngIf="isBusy" class="p-5">
    <mat-progress-spinner mode="indeterminate"></mat-progress-spinner>
</div>
<form *ngIf="tradeForm" class="container-fluid page-container py-3" [formGroup]="tradeForm" (ngSubmit)="onSubmit()">
    <div class="row">
        <div class="col-md-12">
            <mat-form-field appearance="outline" class="input-full-width">
                <mat-label>Nom</mat-label>
                <input matInput type="text" formControlName="label" required>
            </mat-form-field>
        </div>

        <div class="col-12 text-center">
            <button *ngIf="trade.id" [disabled]="!tradeForm.valid || isSaving" mat-raised-button color="primary"
                    type="submit">
                <mat-spinner *ngIf="isSaving" class="btn-spinner btn-spinner-white"></mat-spinner>
                <mat-icon *ngIf="!isSaving">save</mat-icon>
                Modifier
            </button>
            <button *ngIf="!trade.id" [disabled]="!tradeForm.valid || isSaving" mat-raised-button color="primary"
                    type="submit">
                <mat-spinner *ngIf="isSaving" class="btn-spinner btn-spinner-white"></mat-spinner>
                <mat-icon *ngIf="!isSaving">add</mat-icon>
                Ajouter
            </button>
        </div>
    </div>
</form>
