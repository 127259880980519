<div class="d-flex flex-row align-items-center mt-2">
    <div class="d-flex flex-row flex-fill align-items-center bg-white rounded pr-2 overflow-hidden">
        <a [routerLink]="['/affair-dossier', dossier.id]" matRipple
           class="d-flex flex-row align-self-stretch align-items-center flex-fill cursor-pointer text-decoration-none text-reset pl-2">
            <mat-icon>folder</mat-icon>
            <span class="d-flex mx-2">{{dossier.label}}</span>
        </a>

        <div class="d-flex flex-row justify-content-start align-content-center p-2">
            <span class="mr-3"><i class="far fa-file mr-1"></i> <span>{{dossier.nbDocuments}}</span></span>
            <span class="mr-3" [ngClass]="{'text-danger': dossier.nbDiscutions > 0}"><i
                    class="far fa-comment mr-1"></i> {{dossier.nbDiscutions}}</span>
            <span *ngIf="dossier.societies.length && dossier.allowTo.societies"
                  class="ml-auto d-flex flex-row align-content-center" [matTooltip]="concatTooltip(dossier.users)"
                  matTooltipPosition="above"><mat-icon class="mr-1">people</mat-icon>
                {{dossier.societies.length}}</span>
        </div>


        <button *ngIf="dossier.allowTo.edit" [matMenuTriggerFor]="dossierMenu" [matMenuTriggerData]="{dossier: dossier}"
                mat-icon-button>
            <mat-icon>more_vert</mat-icon>
        </button>
    </div>
    <div class="dossier-state pl-2">
        <mat-icon *ngIf="dossier.state == 3" class="text-success">check_circle</mat-icon>
        <mat-icon *ngIf="dossier.state == 2" class="text-warning">stop_circle</mat-icon>
        <mat-icon *ngIf="dossier.state == 1 || dossier.state == 4" class="text-danger">stop_circle</mat-icon>
    </div>
</div>
<div>
    <app-document *ngFor="let documentHistorique of dossier.documentHistoriques; trackBy: trackBy;"
                  (documentClicked)="documentClickedHandler($event)"
                  (activityAdded)="activityAddedHandler($event)"
                  [documentHistorique]="documentHistorique"
                  [documentHistoriques]="dossier.documentHistoriques"
                  [selectedDocumentHistorique]="selectedDocumentHistorique"
                  [dossier]="dossier"
                  [viewContainerRef]="viewContainerRef">
    </app-document>
</div>
<mat-menu #dossierMenu="matMenu" xPosition="before">
    <ng-template matMenuContent let-dossier="dossier">
        <button mat-menu-item (click)="onTapEdit()">
            <mat-icon>edit</mat-icon>
            <span>Modifier</span>
        </button>
        <button mat-menu-item (click)="onTapAuthorize()">
            <mat-icon>admin_panel_settings</mat-icon>
            <span>Autoriser</span>
        </button>
        <button *ngIf="dossier.allowTo.remove" mat-menu-item (click)="onTapDelete()">
            <mat-icon class="text-danger">delete_outline</mat-icon>
            <span>Supprimer</span>
        </button>
    </ng-template>
</mat-menu>
