import {User} from '@src/app/model/user.model';
import {Trade} from '@src/app/model/trade.model';
import {Society} from '@src/app/model/society.model';

export class Contact {
    id: number;
    name?: string;
    surname?: string;
    fullName?: string;
    initiale?: string;
    civility?: any;
    position?: string;
    address?: string;
    postalCode?: string;
    city?: string;
    country?: string;
    phone?: string;
    email?: string;
    user?: User;
    society?: Society;
    societyId?: number;
    trades?: Trade[];

    constructor(
        id?: number,
        name?: string,
        surname?: string,
        civility?: any,
        position?: string,
        address?: string,
        postalCode?: string,
        city?: string,
        country?: string,
        phone?: string,
        email?: string,
        trades?: [],
    ) {
        this.id = id || null;
        this.name = name || '';
        this.surname = surname || '';
        this.civility = civility || '';
        this.position = position || '';
        this.address = address || '';
        this.postalCode = postalCode || '';
        this.city = city || '';
        this.country = country || '';
        this.phone = phone || '';
        this.email = email || '';
        this.trades = trades || [];
    }
}

export const CIVILITES = [
    {key: '1', label: 'Homme'},
    {key: '2', label: 'Femme'},
];
