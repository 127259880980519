<div class="d-flex flex-row align-items-center">
    <a mat-button class="btn-rounded has-ripple" routerLink="/home">
        <mat-icon color="primary">arrow_back_ios</mat-icon>
        <span class="fz-18 d-none d-lg-inline-block">Tableau de bord</span>
    </a>
    <button (click)="onTapView('user')" [class.active]="views.isUser" mat-icon-button color="primary"
            matTooltip="Utilisateurs" matTooltipPosition="above" class="ml-2">
        <mat-icon>people_alt</mat-icon>
    </button>
    <button (click)="onTapView('society')" [class.active]="views.isSociety" mat-icon-button color="primary"
            matTooltip="Sociétés" matTooltipPosition="above" class="ml-3">
        <mat-icon>business</mat-icon>
    </button>
    <button (click)="onTapView('trade')" [class.active]="views.isTrade" mat-icon-button color="primary"
            matTooltip="Corps d'état" matTooltipPosition="above" class="ml-2">
        <mat-icon>construction</mat-icon>
    </button>
</div>

<!-- <mat-form-field *ngIf="!hasRoles(['ROLE_GUEST'])" (click)="navigateToSearchPage()"
                appearance="outline" class="mat-input-search pb-0 align-self-end ml-3">
    <mat-label>Saisir ma recherche</mat-label>
    <span matPrefix><mat-icon>search</mat-icon></span>
    <input matInput>
</mat-form-field> -->