import {Component, Inject, OnInit} from '@angular/core';
import {MatDialogRef, MAT_DIALOG_DATA} from '@angular/material/dialog';
import {ApiService} from '@src/app/services/api.service';
import {Dossier} from '@src/app/model/dossier.model';

@Component({
    selector: 'app-modal-share',
    templateUrl: 'modal-diffuse.component.html',
})
export class ModalDiffuseComponent implements OnInit {

    isLoading: boolean;
    isSaving: boolean;
    message: string;

    constructor(
        @Inject(MAT_DIALOG_DATA) public data: {dossier: Dossier},
        public dialogRef: MatDialogRef<ModalDiffuseComponent>,
        private apiService: ApiService,
    ) {
        this.isLoading = true;
        this.isSaving = false;
    }

    ngOnInit(): void {
        console.log(this.data.dossier);
        this.apiService.getDiffuseMessage(this.data.dossier.id).subscribe((result: {message: string}) => {
            this.message = result.message;
            this.isLoading = false;
        });
    }

    onSubmit(): void {
            this.isSaving = true;
            const data = {
                message: this.message,
            };
            this.apiService.sendDiffuse(this.data.dossier.id, data).subscribe((dossier: Dossier) => {
                this.isSaving = false;
                this.dialogRef.close(dossier);
            });
    }
}
