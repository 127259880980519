import {Component, EventEmitter, Input, OnChanges, Output, ViewContainerRef} from '@angular/core';
import {Dossier} from '@src/app/model/dossier.model';
import {Affair} from '@src/app/model/affair.model';
import {DossierService} from '@src/app/services/dossier.service';
import {ActionResult, ActionStateResult, Context, SimpleChanges} from '@src/app/shared/interfaces';
import {BaseComponent} from '@src/app/shared/base.component';
import {takeUntil} from 'rxjs/operators';

@Component({
  selector: 'app-etape-dossier',
  templateUrl: './etape-dossier.component.html',
  styleUrls: ['./etape-dossier.component.scss']
})
export class EtapeDossierComponent extends BaseComponent implements OnChanges {

  @Input() dossier: Dossier;
  @Input() affair: Affair;
  @Input() activeStep: any;
  @Input() viewContainerRef: ViewContainerRef;
  @Input() dropState: any;
  @Output() affairUpdate = new EventEmitter<{result: ActionStateResult, dossier: Dossier}>();
  isActionBusy = false;

  constructor(
      private dossierService: DossierService,
  ) {
    super();
  }

  ngOnChanges(changes: SimpleChanges): void {
    if (
        changes.dropState
        && changes.dropState.previousValue === undefined
        && changes.dropState.currentValue !== undefined
    ) {
      switch (changes.dropState.currentValue) {
          case 1:
              this.onTapDo(true);
              break;
          case 2:
              this.onTapWork(true);
              break;
          case 3:
              this.onTapValid(true);
              break;
      }
    }
  }

  onTapEdit() {
    this.dossierService.edit(this.dossier, false, this.viewContainerRef)
        .pipe(takeUntil(this.notifier))
        .subscribe((dossier: Dossier) => {
          if (dossier) { this.afterActionUpdate(dossier); }
        });
  }

  onTapAuthorize(): void {
    this.dossierService.authorize(this.dossier, null, false, false, this.viewContainerRef)
        .pipe(takeUntil(this.notifier))
        .subscribe((dossier: Dossier) => {
          if (dossier) { this.afterActionUpdate(dossier); }
        });
  }

  private afterActionUpdate(dossier: Dossier) {
    let affairState = this.affair.affairSteps[this.activeStep].states[this.dossier.state];
    affairState = affairState.map(state => state.id === dossier.id ? dossier : state);
    this.affair.affairSteps[this.activeStep].states[this.dossier.state] = affairState;
  }

  onTapDelete() {
    this.dossierService.delete(this.dossier).subscribe((result: ActionResult) => {
      if (result.success) {
        let affairState = this.affair.affairSteps[this.activeStep].states[this.dossier.state];
        affairState = affairState.filter(state => state.id !== this.dossier.id);
        this.affair.affairSteps[this.activeStep].states[this.dossier.state] = affairState;
      }
    });
  }

  onTapDo(isDrop: boolean = false) {
    this.isActionBusy = true;
    this.dossierService.state(this.dossier, 1, true)
        .pipe(takeUntil(this.notifier))
        .subscribe((result: ActionStateResult) => {
          if (result.success) {
            this.afterStateUpdate(result, 1, isDrop);
          }
        });
  }

  onTapWork(isDrop: boolean = false) {
    this.isActionBusy = true;
    this.dossierService.state(this.dossier, 2, true)
        .pipe(takeUntil(this.notifier))
        .subscribe((result: ActionStateResult) => {
          if (result.success) {
            this.afterStateUpdate(result, 2, isDrop);
          }
        });
  }

  onTapValid(isDrop: boolean = false) {
    this.isActionBusy = true;
    this.dossierService.state(this.dossier, 3, true)
        .pipe(takeUntil(this.notifier))
        .subscribe((result: ActionStateResult) => {
          if (result.success) {
            this.afterStateUpdate(result, 3, isDrop);
          }
        });
  }

  private afterStateUpdate(result: ActionStateResult, currentState: number, isDrop: boolean) {
    const previousState = this.dossier.state;
    this.dossier.state = currentState;
    if (!isDrop) {
      const previous = this.affair.affairSteps[this.activeStep].states[previousState];
      this.affair.affairSteps[this.activeStep].states[previousState] = previous.filter(d => d.id !== this.dossier.id);
      this.affair.affairSteps[this.activeStep].states[currentState].push(this.dossier);
    }
    this.affairUpdate.emit({result: result, dossier: this.dossier});
    this.isActionBusy = false;
  }

  onTapMore(dossier: Dossier): void {
    this.dossierService.more(dossier, 'step', this.viewContainerRef)
        .pipe(takeUntil(this.notifier))
        .subscribe((context: Context) => {
          if (context && context.action) {
            setTimeout(() => { this[context.action](); }, 300);
          }
        });
  }
}
