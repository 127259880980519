import {AfterViewChecked, ChangeDetectorRef, Component, Inject, OnDestroy, OnInit} from '@angular/core';
import {Subject} from 'rxjs';
import {Dossier} from '@src/app/model/dossier.model';
import {MAT_DIALOG_DATA, MatDialogRef} from '@angular/material/dialog';
import {ApiService} from '@src/app/services/api.service';
import {ToastService} from '@src/app/services/toast.service';
import {takeUntil} from 'rxjs/operators';
import {HttpEvent, HttpEventType} from '@angular/common/http';

@Component({
    selector: 'app-modal-file',
    templateUrl: 'modal-file-component.html',
    styleUrls: ['modal-file-component.scss'],
})
export class ModalFileComponent implements OnInit, OnDestroy, AfterViewChecked {

    notifier = new Subject();
    dossier: Dossier;
    isLoading: boolean;
    isSaving: boolean;
    uploading: boolean;
    processing: boolean;
    progress: number;

    constructor(
        public dialogRef: MatDialogRef<ModalFileComponent>,
        @Inject(MAT_DIALOG_DATA) public data: any,
        private apiService: ApiService,
        private toastService: ToastService,
        private cdRef: ChangeDetectorRef,
    ) {
    }

    ngOnInit(): void {
        this.isLoading = true;
        this.isSaving = false;

        this.apiService.getDossier(this.data.dossier.id)
            .pipe(takeUntil(this.notifier))
            .subscribe((dossier: Dossier) => {
                this.dossier = dossier;
                this.isLoading = false;
            });
    }

    ngOnDestroy(): void {
        this.notifier.next();
        this.notifier.complete();
    }

    ngAfterViewChecked(): void {
        this.cdRef.detectChanges();
    }

    onClose(args = null) {
        this.dialogRef.close(args);
    }

    handleFileInput(files) {
        const formData: FormData = new FormData();
        this.progress = 0;
        this.uploading = true;
        this.processing = false;

        if (files.length > 1) {
            for (const file of files) {
                formData.append('files[]', file, file.name);
            }
        } else {
            formData.append('files[]', files[0], files[0].name);
        }

        this.apiService.uploadDocuments(this.dossier.id, formData)
            .pipe(takeUntil(this.notifier))
            .subscribe((event: HttpEvent<any>) => {
                switch (event.type) {
                    case HttpEventType.Sent:
                        this.processing = true;
                        break;
                    case HttpEventType.ResponseHeader:
                        break;
                    case HttpEventType.UploadProgress:
                        this.progress = Math.round(event.loaded / event.total * 100);
                        break;
                    case HttpEventType.Response:
                        this.apiService.getDossier(this.dossier.id)
                            .pipe(takeUntil(this.notifier))
                            .subscribe((dossier) => {
                                this.dossier = dossier;
                                this.progress = 0;
                                this.uploading = false;
                            });
                        break;
                }
            }, () => {
                this.progress = 0;
                this.uploading = false;
                this.processing = false;
            });
    }

    trackByFn(index, item) {
        return index;
    }
}
