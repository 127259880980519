import {Component, EventEmitter, OnInit, Input, Output, ViewContainerRef, ViewChild} from '@angular/core';
import {ActivatedRoute, Router} from '@angular/router';
import {Dossier, DossierSpecifUser, SpecificVersion} from '@src/app/model/dossier.model';
import {DocumentHistorique} from '@src/app/model/document-historique.model';
import {AffairNavItems, ClicEmitter, ActionVersionResult} from '@src/app/shared/interfaces';
import {take, takeUntil} from 'rxjs/operators';
import {MatSelectionList, MatSelectionListChange} from '@angular/material/list';
import {HttpEvent, HttpEventType} from '@angular/common/http';
import {Location} from '@angular/common';
import {DossierService} from '@src/app/services/dossier.service';
import {BaseComponent} from '@src/app/shared/base.component';
import {MatDrawerMode} from '@angular/material/sidenav';
import {ModalService} from '@src/app/services/modal.service';
import {UploadImageService} from '@src/app/services/upload-image.service';
import {DocumentService} from '@src/app/services/document.service';
import {combineLatest} from 'rxjs';

@Component({
    selector: 'app-specific-details',
    templateUrl: './specific-detail.component.html',
    styleUrls: ['./specific-detail.component.scss'],
})
export class SpecificDetailComponent extends BaseComponent implements OnInit {

    @Input() documentHistorique: DocumentHistorique;
    @Input() documentHistoriques: DocumentHistorique[];
    @Output() documentClicked = new EventEmitter<ClicEmitter>();
    @Output() userClicked = new EventEmitter<ClicEmitter>();
    @ViewChild('specificUsers') specificUsersList: MatSelectionList;
    title = 'Chargement...';
    mode: MatDrawerMode = 'over';
    backdrop = true;
    // TODO Vérifier si notifier est util pour memomry leak ou couper requete au changement de component
    dossier: Dossier;
    isBusy: boolean;
    isActionBusy: boolean;
    isLastVersion: boolean;
    isNewVersion: boolean;
    spinner = {
        allDownload: false,
    };
    userOpen = false;
    dossierSpecificUser: DossierSpecifUser;
    selectedDossierSpecifUser: number;
    selectedIdDocumentHistorique: number;
    selectedDocumentHistorique: DocumentHistorique;
    previewIdDocumentHistorique: number;
    versionParent: {id: number; label: string};
    dossierParent: {id: number; label: string};
    activeStatus = 1;
    // TODO Refactor avec upload
    uploading = false;
    processing = false;
    progress = 0;
    // upload = {
    //     isUploading: false,
    //     isProcessing: false,
    //     progress: 0,
    // };
    back: any[];
    querySearch: any;
    items: AffairNavItems = {
        back: true,
        state: false,
        views: false,
        collaboratif: true,
        equipe: true,
    };

    constructor(
        public viewContainerRef: ViewContainerRef,
        private router: Router,
        private activatedRoute: ActivatedRoute,
        private location: Location,
        private dossierService: DossierService,
        private modalService: ModalService,
        private uploadImageService: UploadImageService,
        private documentService: DocumentService,
    ) {
        super();
        this.sharedService.updateTitle(this.title);
        this.sharedService.updateMode(this.mode);
        this.sharedService.updateBackdrop(this.backdrop);
    }

    ngOnInit(): void {
        this.isBusy = true;
        this.title = 'Chargement...';
        this.isActionBusy = false;
        this.isNewVersion = false;
        combineLatest([this.activatedRoute.queryParams, this.activatedRoute.params])
            .pipe(take(1))
            .subscribe(([queryParams, params]) => {
                if (queryParams['_p']) {
                    this.previewIdDocumentHistorique = queryParams['_p'];
                    this.selectedIdDocumentHistorique = queryParams['_p'];
                }
                if (queryParams['_d']) {
                    this.selectedIdDocumentHistorique = queryParams['_d'];
                }
                if (queryParams['_a']) {
                    this.isNewVersion = !!parseInt(queryParams['_a'], 10);
                }

                this.getDossier(params['id'], params['user'], this.isNewVersion);
            });
    }

    getDossier(id: number, user: any = null, isNewVersion: boolean = false): void {
        this.dossierService.get(id)
            .pipe(takeUntil(this.notifier))
            .subscribe((dossier) => {
                this.dossier = dossier;
                this.versionParent = this.dossier.parentDossiers.find(pd => pd.id === this.dossier.parentId);
                this.dossierParent = this.dossier.parentDossiers[0];
                this.title = this.dossier.affair.label + ' - ' + this.versionParent.label;
                this.sharedService.updateTitle(this.title);
                this.back = ['/specific', this.dossier.parentDossiers[0].id];
                this.querySearch = {
                    affair: this.dossier.affair.id,
                        step: this.dossier.step.id,
                        dirid: this.dossier.id,
                        dirName: this.dossier.label,
                };

                this.dossier.documentHistoriques.map(dh => {
                    if (parseInt(dh.id + '', 10) === parseInt(this.selectedIdDocumentHistorique + '', 10)) {
                        this.selectedDocumentHistorique = dh;
                    }
                });

                if (user) {
                    this.selectedDossierSpecifUser = parseInt(user, 10);
                    // tslint:disable-next-line:max-line-length
                    this.dossierSpecificUser = this.dossier.specificDetail.users.find(dsu => dsu.user.id === this.selectedDossierSpecifUser);
                    this.onTapUser(this.dossierSpecificUser);
                    this.userOpen = true;
                } else {
                    this.selectedDossierSpecifUser = null;
                    this.userOpen = false;
                }
                this.afterGetDossier();
                if (isNewVersion) {
                    this.isNewVersion = false;
                    setTimeout(() => {
                        this.onTapAuthorize(this.dossier);
                    }, 300);
                }
            });
    }

    afterGetDossier(): void {
        const index = this.dossier.specificDetail.versions.findIndex(v => v.id === this.dossier.specificDetail.id);
        this.isLastVersion = index === (this.dossier.specificDetail.versions.length - 1);
        this.isBusy = false;
        this.isActionBusy = false;
    }

    actionUserHandler(value: {dossier: number; type: string}): void {
        this.dossier.specificDetail.users.map(su => {
            if (su.dossier.id === value.dossier) {
                su.type = value.type;
            }
            return su;
        });
    }

    // both but commented on app
    onTapDownloadAllDocument(): void {
        this.spinner.allDownload = true;
        this.dossierService.download(this.dossier, this.dossier).then(() => {
            this.spinner.allDownload = false;
        }, () => this.spinner.allDownload = false);
    }

    onSelectSpecificUser($event: MatSelectionListChange): void {
        this.dossierSpecificUser = <DossierSpecifUser>$event.option.value;
        const url = this.router.createUrlTree(['specific-detail', this.dossier.id, this.dossierSpecificUser.user.id]).toString();
        this.location.replaceState(url);
        this.userOpen = true;
    }

    onTapShorcut(dossier: Dossier): void {
        this.dossierService.shortcut(dossier, false, this.viewContainerRef)
            .pipe(takeUntil(this.notifier))
            .subscribe((result: Dossier) => {
                if (result) { this.dossier.shortcuts = result.shortcuts; }
            });
    }

    onTapAuthorize(dossier: Dossier): void {
        this.dossierService.authorize(dossier, null, false, false, this.viewContainerRef)
            .pipe(takeUntil(this.notifier))
            .subscribe((result: Dossier) => {
                if (result) { this.dossier = result; }
            });
    }

    // TODO On peux utilisé onTapAuthorizeDossier partout, mais refaire le cheminement de modal coté appli
    // onTapAuthorizeDossierModal(dossier: Dossier): void {
    //     this.modalService.show(
    //         {id: dossier.id, type: 'dossier-societies'},
    //         'base-modal',
    //         this.viewContainerRef,
    //         true
    //     ).subscribe((result) => {
    //         if (result) {
    //             this.dossier.dossiers = this.dossier.dossiers.map(d => d.id === result.id ? result : d);
    //             this.dossier.specificDetail.dossiers = this.dossier.specificDetail.dossiers.map(d => d.id === result.id ? result : d);
    //         }
    //     });
    // }

    onTapAddDossier(dossier: Dossier): void {
        this.dossierService.add(dossier, false, this.viewContainerRef).subscribe((result: Dossier) => {
            if (result) {
                this.dossier.dossiers.push(result);
                this.dossier.specificDetail.dossiers.push(result);
            }
        });
    }

    // TODO On peux utilisé onTapAddDossier partout, mais refaire le cheminement de modal coté appli
    // onTapAddDossierModal(dossier: Dossier): void {
    //     this.modalService.show(
    //         {
    //             type: 'dossier-add',
    //             state: dossier.state,
    //             affair: dossier.affair.id,
    //             step: dossier.step.id,
    //             parentDossiers: dossier.id,
    //         },
    //         'base-modal',
    //         this.viewContainerRef,
    //         true
    //     ).subscribe((result) => {
    //         if (result) {
    //             this.dossier.dossiers.push(result);
    //
    //             if (result.typeSpecific > 0) {
    //                 setTimeout(() => {
    //                     this.onTapAuthorizeDossierModal(result);
    //                 }, 600);
    //             }
    //         }
    //     });
    // }

    // TODO Voir pour mettre dans Utils ou Dossier & Document Services
    handleFileInput(files): void {
        this.progress = 0;
        this.uploading = true;
        this.processing = false;

        this.dossierService.upload(this.dossier, files)
            .subscribe((event: HttpEvent<any>) => {
                switch (event.type) {
                    case HttpEventType.Sent:
                        this.processing = true;
                        break;
                    case HttpEventType.ResponseHeader:
                        break;
                    case HttpEventType.UploadProgress:
                        this.progress = Math.round(event.loaded / event.total * 100);
                        break;
                    case HttpEventType.Response:
                        this.dossierService.get(this.dossier.id)
                            .pipe(takeUntil(this.notifier))
                            .subscribe((dossier) => {
                                this.dossier = dossier;
                                this.progress = 0;
                                this.uploading = false;
                            });
                        break;
                }
            }, () => {
                this.progress = 0;
                this.uploading = false;
                this.processing = false;
            });
    }

    onTapEdit(dossier: Dossier): void {
        const parentDossier = dossier.parentDossiers.find(d => d.id === dossier.parentId);
        const data = <Dossier>{
            id: dossier.parentId,
            label: parentDossier.label
        };
        this.dossierService.edit(data, false, this.viewContainerRef).subscribe((result: Dossier) => {
            if (result) {
                this.versionParent.label = result.label;
                this.title = this.dossier.affair.label + ' - ' + this.versionParent.label;
                this.sharedService.updateTitle(this.title);
            }
        });
    }

    // TODO On peux utilisé onTapEditDossier partout, mais refaire le cheminement de modal coté appli
    // onTapEditModal(dossier: Dossier) {
    //     this.modalService.show(
    //         {id: dossier.parentId, type: 'dossier-edit'},
    //         'base-modal',
    //         this.viewContainerRef,
    //         true
    //     ).subscribe((result) => {
    //         if (result) { this.dossier = result; }
    //     });
    // }

    onTapMoreVersionDossier(version: SpecificVersion): void {
        const url = this.router.createUrlTree(['specific-detail', version.id]).toString();
        this.location.replaceState(url);
        this.title = 'Chargement...';
        this.isActionBusy = true;
        this.getDossier(version.id);
    }

    onTapVersion(dossier: Dossier): void {
        this.title = 'Traitement...';
        this.isActionBusy = true;
        this.dossierService.version(dossier)
            .subscribe((result: ActionVersionResult) => {
                if (result.success) {
                    const url = this.router.createUrlTree(['specific-detail', result.version.id]).toString();
                    this.location.replaceState(url);
                    this.getDossier(result.version.id, null, true);
                }
            });
    }

    onTapDateLimit(): void {
        this.dossierService.dateLimite(this.dossier, this.viewContainerRef).subscribe((result: {dateLimit: string}) => {
            if (result) { this.dossier.dateLimit = result.dateLimit; }
        });
    }

    onTapDelete(version: Dossier): void {
        this.title = 'Traitement...';
        this.isActionBusy = true;
        const index: number = version.specificDetail.versions.findIndex(v => v.id === version.id);
        this.dossierService.delete(version)
            .subscribe((result) => {
                if (result) {
                    if (index > 1) {
                        const newVersion = version.specificDetail.versions[index - 1];
                        const url = this.router.createUrlTree(['specific-detail', newVersion.id]).toString();
                        this.location.replaceState(url);
                        this.title = 'Chargement...';
                        this.getDossier(newVersion.id);
                    } else {  this.router.navigate(this.back);  }
                } else { this.isActionBusy = false; }
            }, () => this.isActionBusy = false );
    }

    dossierDeletedHandler(dossier: Dossier): void {
        this.dossier.dossiers = this.dossier.dossiers.filter(d => d.id !== dossier.id);
        this.dossier.specificDetail.dossiers = this.dossier.specificDetail.dossiers.filter(d => d.id !== dossier.id);
    }

    onTapUser(specificUser: DossierSpecifUser): void {
        // tslint:disable-next-line:max-line-length
        this.dossierService.specificUser(specificUser, this.previewIdDocumentHistorique, this.selectedIdDocumentHistorique, this.viewContainerRef)
            .pipe(takeUntil(this.notifier))
            .subscribe((result: DossierSpecifUser) => {
                if (result) {
                    this.dossier.specificDetail.users.map(su => su.dossier.id === result.dossier.id ? result : su);
                }
            });
    }

    onTapMoreVersion(): void {
        this.dossierService.moreSpecific(this.dossier.specificDetail, 'version', this.viewContainerRef)
            .pipe(takeUntil(this.notifier))
            .subscribe(result => {
                if (result && result.action) {
                    setTimeout(() => {
                        console.log(result);
                        this.title = 'Chargement...';
                        this.isActionBusy = true;
                        this.getDossier(result.action);
                    }, 300);
                }
            });
    }

    onTapMoreAction(): void {
        this.dossierService.moreSpecific(this.dossier.specificDetail, 'detail', this.viewContainerRef)
            .pipe(takeUntil(this.notifier))
            .subscribe(result => {
                if (result && result.action) {
                    setTimeout(() => {
                        console.log(result);
                        this[result.action](this.dossier);
                    }, 300);
                }
            });
    }

    private uploadFile(dossier, event) {
        this.documentService.waitingFile(dossier, event, 'upload', this.viewContainerRef)
            .pipe(takeUntil(this.notifier))
            .subscribe((result) => {
                if (result) {
                    this.getDossier(dossier.id);
                }
            });
    }

    private onTapAddFile(dossier: Dossier) {
        this.uploadImageService.uploadFile('upload').then((event) => {
            setTimeout(() => {
                this.uploadFile(dossier, event);
            }, 300);
        });
    }

    private onTapAddMedia(dossier: Dossier) {
        this.uploadImageService.uploadMedia('upload').then((event) => {
            setTimeout(() => {
                this.uploadFile(dossier, event);
            }, 300);
        });
    }

    private onTapAddCamera(dossier: Dossier) {
        this.uploadImageService.uploadCamera('upload').then((event) => {
            setTimeout(() => {
                this.uploadFile(dossier, event);
            }, 300);
        });
    }

    public onTapMoreAdd(dossier: Dossier) {
        this.documentService.moreFile(this.dossier, 'upload', this.viewContainerRef)
            .pipe(takeUntil(this.notifier))
            .subscribe(result => {
                if (result && result.action) {
                    setTimeout(() => {
                        this[result.action](dossier);
                    }, 300);
                }
            });
    }

    // TODO mettre specific trackBy dans service associé
    trackByDossier(index, item): number {
        return item.dossier.id;
    }

    // TODO mettre specific sortBy dans service associé
    sortByDocument(): DocumentHistorique[] {
        const prop1 = 'isFavoris';
        const prop2 = 'name';
        return this.dossier.specificDetail.documentHistoriques.sort((a, b) => {
            const keyA1 = a[prop1];
            const keyA2 = a[prop2].toLowerCase();
            const keyB1 = b[prop1];
            const keyB2 = b[prop2].toLowerCase();

            if (keyA1 === false && keyB1 === true) { return 1; }
            if (keyA1 === true && keyB1 === false) { return -1; }
            if (keyA2 < keyB2) { return -1; }
            if (keyA2 > keyB2) { return 1; }
            return 0;
        });
    }

    // TODO mettre specific sortBy dans service associé
    sortByUser(objects: any[], prop: string): any[] {
        return objects.sort((a, b) => a.user[prop].toLowerCase() > b.user[prop].toLowerCase() ? 1 : a.user[prop] === b.user[prop] ? 0 : -1);
    }
}
