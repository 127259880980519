import {Component, OnInit, OnDestroy} from '@angular/core';
import {MatDialogRef} from '@angular/material/dialog';
import {FormControl, FormGroup} from '@angular/forms';
import {ApiService} from '@src/app/services/api.service';
import {ToastService} from '@src/app/services/toast.service';
import {Trade} from '@src/app/model/trade.model';
import {ROLES, User} from '@src/app/model/user.model';
import {CIVILITES} from '@src/app/model/contact.model';
import {Affair, PAYS} from '@src/app/model/affair.model';
import {Society} from '@src/app/model/society.model';
import {ActivatedRoute, Router} from '@angular/router';
import {Subject} from 'rxjs';
import {takeUntil} from 'rxjs/operators';
import {FormService} from '@src/app/services/form.service';

@Component({
    selector: 'app-guest-modal-view-content',
    templateUrl: 'guest-modal-view-content.component.html',
})
export class GuestModalViewContentComponent implements OnInit, OnDestroy {

    title = 'Ajouter un invité';
    notifier = new Subject();
    isBusy: boolean;
    isSaving = false;
    user: User;
    userForm: FormGroup;
    civilities = CIVILITES;
    roles = ROLES;
    trades: Trade[];
    affairs: Affair[];
    societies: Society[];

    constructor(
        public dialogRef: MatDialogRef<GuestModalViewContentComponent>,
        private apiService: ApiService,
        private router: Router,
        private activatedRoute: ActivatedRoute,
        private toastService: ToastService,
        private formService: FormService,
    ) {}

    ngOnInit(): void {
        this.isBusy = true;
        this.user = new User();
        this.user.contact.country = PAYS[0];
        this.createForm();
    }

    ngOnDestroy(): void {
        this.notifier.next();
        this.notifier.complete();
    }

    onClose(args: User = null) {
        this.formService.user = args;
        this.dialogRef.close(args);
    }

    createForm() {
        this.isBusy = false;
        this.userForm = new FormGroup({
            name: new FormControl(this.user.contact.name),
            surname: new FormControl(this.user.contact.surname),
            email: new FormControl(this.user.contact.email),
            phone: new FormControl(this.user.contact.phone),
            hasUser: new FormControl(1),
        });
    }

    onSubmit(): void {
        if (this.userForm.valid) {
            this.isSaving = true;
            this.apiService.postGuest(this.userForm.value)
                .pipe(takeUntil(this.notifier))
                .subscribe((user: User) => {
                this.isSaving = false;
                if (user) {
                    this.toastService.show(`L'invité "${user.fullName}" a été ajouté.`);
                    this.onClose(user);
                }
            }, () => this.isSaving = false);
        }
    }

    private formatArray(array) {
        if (array) {
            return array.map(i => i.hasOwnProperty('id') ? i.id : i);
        }
        return null;
    }
}
