<div fxFlex fxLayout="column">
    <div class="d-flex flex-row justify-content-between align-items-center p-3">
        <div class="d-flex">
            <button (click)="onTapAdd()" mat-stroked-button color="primary" class="btn-rounded">
                <mat-icon>add</mat-icon>
                Ajouter un utilisateur
            </button>
        </div>

        <mat-form-field appearance="outline" class="pb-0">
            <mat-label>Saisir ma recherche</mat-label>
            <span matPrefix><mat-icon>search</mat-icon></span>
            <input matInput type="search" (keyup.enter)="onSearch()" [(ngModel)]="search">
            <mat-icon matSuffix (click)="clearSearch()" class="cursor-pointer">close</mat-icon>
        </mat-form-field>
    </div>
    <div fxFlex fxLayoutAlign="center center" *ngIf="isBusy" class="p-5">
        <mat-progress-spinner mode="indeterminate"></mat-progress-spinner>
    </div>

    <!-- nouvelle liste -->
    <div *ngFor="let user of users" class="d-flex flex-row justify-content-start align-items-center text-left bg-primary-light rounded mx-3 mb-3 pl-3 py-1">
        <div (click)="onTapEdit(user)" matTooltip="Modifier l'utilisateur" matTooltipPosition="above" class="d-flex flex-row justify-content-start align-items-center cursor-pointer w-100">
            <div class="d-flex flex-row align-items-center">
                <mat-icon>person</mat-icon>
                <span class="ml-1 mr-3">{{user.fullName}}</span>
                <span *ngIf="user.contact" class="chip__blue fz-12">
                    {{user.contact.society.name}}
                </span>
            </div>
            <div class="ml-5">
                <a [routerLink]="['/affaire', affair.id]" *ngFor="let affair of user.affairs"
                    class="chip__grey fz-12">{{affair.label}}</a>
            </div>
        </div>
        <button mat-icon-button [matMenuTriggerFor]="menuUser" [matMenuTriggerData]="{user: user}"
                matTooltip="Menu édition" matTooltipPosition="above" class="ml-auto">
            <mat-icon>more_vert</mat-icon>
        </button>
    </div>
    <!-- ancienne liste -->
    <!-- <div *ngFor="let user of users" fxFlex fxLayout="row" fxLayoutAlign="start center"
         class="user-grid-wrapper text-left bg-primary-light">
        <mat-icon class="user-grid-icon">person</mat-icon>
        <div class="d-flex flex-column flex-fill cursor-pointer" (click)="onTapEdit(user)">
            <div>{{user.fullName}} <span *ngIf="user.contact"
                                         class="badge-pill badge-pill-light">{{user.contact.society.name}}</span></div>
            <div><a [routerLink]="['/affaire', affair.id]" *ngFor="let affair of user.affairs"
                    class="badge-pill badge-pill-primary text-decoration-none mr-1">{{affair.label}}</a></div>
        </div>
        <button mat-icon-button [matMenuTriggerFor]="menuUser" [matMenuTriggerData]="{user: user}" class="ml-auto">
            <mat-icon>more_vert</mat-icon>
        </button>
    </div> -->

    <!-- menu édition société -->
    <mat-menu #menuUser="matMenu" xPosition="before">
        <ng-template matMenuContent let-user="user">
            <a *ngIf="user.contact.phone" mat-menu-item href="tel:{{user.contact.phone}}">
                <mat-icon>phone</mat-icon>
                <span>{{user.contact.phone}}</span>
            </a>
            <a *ngIf="user.email" mat-menu-item href="mailto:{{user.email}}">
                <mat-icon>mail_outline</mat-icon>
                <span>{{user.email}}</span>
            </a>
            <button mat-menu-item (click)="onTapEdit(user)">
                <mat-icon>edit</mat-icon>
                <span>Modifier</span>
            </button>
            <button mat-menu-item (click)="onTapDelete(user)">
                <mat-icon class="text-danger">delete_outline</mat-icon>
                <span>Supprimer</span>
            </button>
        </ng-template>
    </mat-menu>
</div>
