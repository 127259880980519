import {Component, OnInit, ViewChild, ViewContainerRef} from '@angular/core';
import {ActivatedRoute, Router} from '@angular/router';
import {ApiService} from '@src/app/services/api.service';
import {ToastService} from '@src/app/services/toast.service';
import {AlertService} from '@src/app/services/alert.service';
import {Affair} from '@src/app/model/affair.model';
import {ModalService} from '@src/app/services/modal.service';
import {Observable, Subscription} from 'rxjs';
import {MatSelect} from '@angular/material/select';
import {MatInput} from '@angular/material/input';
import {BaseComponent} from '@src/app/shared/base.component';
import {MatDrawerMode} from '@angular/material/sidenav';

@Component({
    selector: 'app-affair-list',
    templateUrl: './affair-list.component.html',
    styleUrls: ['./affair-list.component.scss']
})
export class AffairListComponent extends BaseComponent implements OnInit {

    @ViewChild('name') inputName: MatInput;
    @ViewChild('state') selectState: MatSelect;
    @ViewChild('step') selectStep: MatSelect;
    @ViewChild('user') selectUser: MatSelect;

    title = 'Liste des affaires';
    mode: MatDrawerMode = 'over';
    backdrop = true;
    affairs: Affair[];
    isBusy: boolean;
    isGrid: boolean;
    isFilterActif: boolean;
    filterSubscription: Subscription;
    affairSubscription: Subscription;

    constructor(
        private router: Router,
        private activatedRoute: ActivatedRoute,
        private apiService: ApiService,
        private toastService: ToastService,
        private alertService: AlertService,
        public viewContainerRef: ViewContainerRef,
        private modalService: ModalService,
    ) {
        super();
        this.sharedService.updateTitle(this.title);
        this.sharedService.updateBackdrop(this.backdrop);
        this.sharedService.updateMode(this.mode);
    }

    ngOnInit(): void {
        this.isBusy = true;
        this.isFilterActif = false;
        this.getFilterState();

        if (this.storageService.affairListView) {
            this.isGrid = this.storageService.affairListView === 'grid';
        } else {
            this.isGrid = true;
            this.storageService.affairListView = 'grid';
        }
        this.affairSubscription = this.apiService.getAffairs().subscribe((result) => {
            this.affairs = result.affairs;
            this.isBusy = false;
        });
    }

    onToggleView(value: boolean) {
        this.isGrid = value;
        this.storageService.affairListView = value ? 'grid' : 'list';
    }

    onTapAddModal() {
        this.modalService.show(
            { type: 'affair-add' },
            'base-modal',
            this.viewContainerRef,
            true
        ).subscribe((result) => {
            if (result) { this.affairs.unshift(result); }
        });
    }

    navigateToSearchPage() {
        this.router.navigate(['/rechercher']);
    }

    affairDeleteHandler(affair: Affair): void {
        this.affairs = this.affairs.filter(a => a.id !== affair.id);
    }

    getFilterState(): void {
        const storageFilter = this.storageService.affairListFilter;
        // tslint:disable-next-line:max-line-length
        this.isFilterActif = !!(storageFilter && (storageFilter.search || storageFilter.state || storageFilter.step || storageFilter.user));
    }

    affairFilterHandler(affair$: Observable<Affair[]>): void {
        this.isBusy = true;
        this.getFilterState();
        if (typeof this.affairSubscription !== 'undefined') { this.affairSubscription.unsubscribe(); }
        if (typeof this.filterSubscription !== 'undefined') { this.filterSubscription.unsubscribe(); }
        this.filterSubscription = affair$.subscribe((result) => {
            this.affairs = result;
            this.isBusy = false;
        });
    }
}
