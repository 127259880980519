import {Component, OnInit, ViewContainerRef} from '@angular/core';
import {ActivatedRoute, Router} from '@angular/router';
import {FormGroup, FormControl} from '@angular/forms';
import {ApiService} from '@src/app/services/api.service';
import {ToastService} from '@src/app/services/toast.service';
import {Society, TYPES, ENTITIES, THIRD_PARTIES} from '@src/app/model/society.model';
import {Trade} from '@src/app/model/trade.model';
import {BaseComponent} from '@src/app/shared/base.component';
import {MatDrawerMode} from '@angular/material/sidenav';

@Component({
    selector: 'app-annuaire-societe-form',
    templateUrl: './annuaire-society-form.component.html',
    styleUrls: ['./annuaire-society-form.component.scss'],
})
export class AnnuaireSocietyFormComponent extends BaseComponent implements OnInit {
    title = 'Ajouter une société';
    mode: MatDrawerMode = 'over';
    backdrop = true;
    isBusy: boolean;
    isSaving = false;
    society: Society;
    types = TYPES;
    thirdParties = THIRD_PARTIES;
    entities = ENTITIES;
    societyForm: FormGroup;
    trades: Trade[];
    tradesProvider = {
        key: 'id',
        label: 'label',
        items: []
    };

    constructor(
        private router: Router,
        private activatedRoute: ActivatedRoute,
        private apiService: ApiService,
        private toastService: ToastService,
        public viewContainerRef: ViewContainerRef,
    ) {
        super();
        this.sharedService.updateTitle(this.title);
        this.sharedService.updateMode(this.mode);
        this.sharedService.updateBackdrop(this.backdrop);
        this.activatedRoute.params.subscribe((params) => {
            if (params['id']) {
                this.title = 'Modifier une société';
                this.sharedService.updateTitle(this.title);
            }
        });


    }

    ngOnInit(): void {
        this.isBusy = true;
        this.apiService.getTrades().subscribe((trades) => {
            this.trades = trades.trades;
            this.tradesProvider.items = this.trades;
        });
        this.activatedRoute.params.subscribe((params) => {
            if (params['id']) {
                this.apiService.getSociety(params['id']).subscribe((society) => {
                    this.society = society;
                    this.createForm();
                });
            } else {
                this.society = new Society();
                this.createForm();
            }
        });
    }

    createForm() {
        this.isBusy = false;
        this.society.trades = this.formatArray(this.society.trades);
        this.societyForm = new FormGroup({
            name: new FormControl(this.society.name),
            code: new FormControl(this.society.code),
            alternativeName: new FormControl(this.society.alternativeName),
            type: new FormControl(this.society.type),
            address: new FormControl(this.society.address),
            postalCode: new FormControl(this.society.postalCode),
            city: new FormControl(this.society.city),
            country: new FormControl(this.society.country),
            email: new FormControl(this.society.email),
            web: new FormControl(this.society.web),
            phone: new FormControl(this.society.phone),
            siren: new FormControl(this.society.siren),
            tva: new FormControl(this.society.tva),
            tvaNumber: new FormControl(this.society.tvaNumber),
            thirdParty: new FormControl(this.society.thirdParty),
            legalEntity: new FormControl(this.society.legalEntity),
            capital: new FormControl(this.society.capital),
            trades: new FormControl(this.society.trades),
        });
    }

    onSubmit(): void {
        if (this.societyForm.valid) {
            this.isSaving = true;
            const data = this.formatStrings(this.societyForm.value);
            data.trades = this.formatArray(data.trades);
            if (this.society.id) {
                this.apiService.putSociety(this.society.id, data).subscribe((society) => {
                    this.isSaving = false;
                    if (society) {
                        this.toastService.show(`La société "${society.name}" a été modifié.`);
                        this.router.navigate(['annuaire']);
                    }
                }, () => this.isSaving = false);
            } else {
                this.apiService.postSociety(data).subscribe((society) => {
                    this.isSaving = false;
                    if (society) {
                        this.toastService.show(`La société "${society.name}" a été ajouté.`);
                        this.router.navigate(['annuaire']);
                    }
                }, () => this.isSaving = false);
            }

        }
    }
}
