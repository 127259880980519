<div fxFlex fxLayoutAlign="center center" *ngIf="isBusy" class="p-5">
    <mat-progress-spinner mode="indeterminate"></mat-progress-spinner>
</div>
<form *ngIf="userForm" class="container-fluid page-container py-3" [formGroup]="userForm" (ngSubmit)="onSubmit()">
    <div class="row">
        <div class="col-md-2">
            <mat-form-field appearance="outline" class="input-full-width">
                <mat-label>Civilité</mat-label>
                <mat-select formControlName="civility">
                    <mat-option value="" disabled>Choisir une civilité</mat-option>
                    <mat-option *ngFor="let civility of civilities"
                                [value]="civility.key">{{civility.label}}</mat-option>
                </mat-select>
            </mat-form-field>
        </div>
        <div class="col-md-5">
            <mat-form-field appearance="outline" class="input-full-width">
                <mat-label>Prénom</mat-label>
                <input matInput type="text" formControlName="name" required>
            </mat-form-field>
        </div>
        <div class="col-md-5">
            <mat-form-field appearance="outline" class="input-full-width">
                <mat-label>Nom de famille</mat-label>
                <input matInput type="text" formControlName="surname" required>
            </mat-form-field>
        </div>

        <div class="col-md-12">
            <mat-form-field appearance="outline" class="input-full-width">
                <mat-label>Email</mat-label>
                <input matInput type="email" formControlName="email" required pattern="[a-z0-9._%+-]+@[a-z0-9.-]+\.[a-z]{2,4}$">
            </mat-form-field>
        </div>

        <div class="col-md-6">
            <mat-form-field appearance="outline" class="input-full-width">
                <mat-label>Téléphone</mat-label>
                <input matInput type="text" formControlName="phone">
            </mat-form-field>
        </div>
        <div class="col-md-6">
            <mat-form-field appearance="outline" class="input-full-width">
                <mat-label>Position</mat-label>
                <input matInput type="text" formControlName="position">
            </mat-form-field>
        </div>

        <div class="col-md-12">
            <mat-form-field appearance="outline" class="input-full-width">
                <mat-label>Adresse</mat-label>
                <input matInput type="text" formControlName="address">
            </mat-form-field>
        </div>
        <div class="col-md-3">
            <mat-form-field appearance="outline" class="input-full-width">
                <mat-label>Code postal</mat-label>
                <input matInput type="text" formControlName="postalCode">
            </mat-form-field>
        </div>
        <div class="col-md-5">
            <mat-form-field appearance="outline" class="input-full-width">
                <mat-label>Ville</mat-label>
                <input matInput type="text" formControlName="city">
            </mat-form-field>
        </div>
        <div class="col-md-4">
            <mat-form-field appearance="outline" class="input-full-width">
                <mat-label>Pays</mat-label>
                <input matInput type="text" formControlName="country">
            </mat-form-field>
        </div>
        <div class="col-md-12">
            <mat-form-field appearance="outline" class="input-full-width">
                <mat-label>Entreprise</mat-label>
                <mat-select formControlName="society" required>
                    <mat-option value="" disabled>Choisir une entreprise</mat-option>
                    <mat-option *ngFor="let society of societies" [value]="society.id">{{society.name}}</mat-option>
                </mat-select>
            </mat-form-field>
        </div>
        <div class="col-md-12">
            <mat-form-field appearance="outline" class="input-full-width">
                <mat-label>Corps d'état</mat-label>
                <mat-select multiple formControlName="trades" required>
                    <mat-option value="" disabled>Choisir des corps d'état</mat-option>
                    <mat-option *ngFor="let trade of trades" [value]="trade.id">{{trade.label}}</mat-option>
                </mat-select>
            </mat-form-field>
        </div>
    </div>

    <!-- <div *ngIf="!user.id" class="row" [formGroup]="userForm.get(['user']).get(['plainPassword'])">
        <div class="col-md-6">
            <mat-form-field appearance="outline" class="input-full-width">
                <mat-label>Mot de passe</mat-label>
                <input matInput type="password" formControlName="first" required>
            </mat-form-field>
        </div>
        <div class="col-md-6">
            <mat-form-field appearance="outline" class="input-full-width">
                <mat-label>Répéter le Mot de passe</mat-label>
                <input matInput type="password" formControlName="second" required [errorStateMatcher]="matcher">
            </mat-form-field>
        </div>
    </div> -->

    <div class="row" [formGroup]="userForm.get(['user'])">
        <div class="col-md-12">
            <mat-form-field appearance="outline" class="input-full-width">
                <mat-label>Roles</mat-label>
                <mat-select multiple formControlName="roles" required>
                    <mat-option value="" disabled>Choisir des roles</mat-option>
                    <mat-option *ngFor="let role of rolesList" [value]="role.key">{{role.label}}</mat-option>
                </mat-select>
            </mat-form-field>
        </div>
        <div class="col-md-12">
            <mat-form-field appearance="outline" class="input-full-width">
                <mat-label>Affaires</mat-label>
                <mat-select multiple formControlName="affairs">
                    <mat-option value="" disabled>Choisir des affaires</mat-option>
                    <mat-option *ngFor="let affair of affairs" [value]="affair.id">{{affair.label}}</mat-option>
                </mat-select>
            </mat-form-field>
        </div>
    </div>

    <div class="col-12 text-center">
        <button *ngIf="user.id" [disabled]="!userForm.valid || isSaving" mat-raised-button color="primary"
                type="submit">
            <mat-spinner *ngIf="isSaving" class="btn-spinner btn-spinner-white"></mat-spinner>
            <mat-icon *ngIf="!isSaving">save</mat-icon>
            Modifier
        </button>
        <button *ngIf="!user.id" [disabled]="!userForm.valid || isSaving" mat-raised-button color="primary"
                type="submit">
            <mat-spinner *ngIf="isSaving" class="btn-spinner btn-spinner-white"></mat-spinner>
            <mat-icon *ngIf="!isSaving">add</mat-icon>
            Ajouter
        </button>
    </div>
</form>
