import {Component, OnInit, ViewContainerRef} from '@angular/core';
import {ApiService} from '@src/app/services/api.service';
import {FormGroup, FormControl} from '@angular/forms';
import {ActivatedRoute, Router} from '@angular/router';
import {ToastService} from '@src/app/services/toast.service';
import {User, ROLES} from '@src/app/model/user.model';
import {CIVILITES} from '@src/app/model/contact.model';
import {Trade} from '@src/app/model/trade.model';
import {Affair} from '@src/app/model/affair.model';
import {Society} from '@src/app/model/society.model';
import {ShowOnDirtyErrorStateMatcher} from '@angular/material/core';
import {ErrorStateMatcher} from '@src/app/shared/utils';
import {BaseComponent} from '@src/app/shared/base.component';
import {MatDrawerMode} from '@angular/material/sidenav';

@Component({
    selector: 'app-annuaire-user-form',
    templateUrl: './annuaire-user-form.component.html',
    styleUrls: ['./annuaire-user-form.component.scss'],
})
export class AnnuaireUserFormComponent extends BaseComponent implements OnInit {
    title = 'Ajouter un utilisateur';
    mode: MatDrawerMode = 'over';
    backdrop = true;
    isBusy: boolean;
    isSaving = false;
    user: User;
    userForm: FormGroup;
    matcher: ShowOnDirtyErrorStateMatcher;
    civilities = CIVILITES;
    rolesList = ROLES;
    trades: Trade[];
    affairs: Affair[];
    societies: Society[];

    constructor(
        private apiService: ApiService,
        private router: Router,
        private activatedRoute: ActivatedRoute,
        private toastService: ToastService,
        public viewContainerRef: ViewContainerRef,
    ) {
        super();
        this.sharedService.updateTitle(this.title);
        this.sharedService.updateMode(this.mode);
        this.sharedService.updateBackdrop(this.backdrop);
        this.activatedRoute.params.subscribe((params) => {
            if (params['id']) {
                this.title = 'Modifier un utilisateur';
                this.sharedService.updateTitle(this.title);
            }
        });

    }

    ngOnInit(): void {
        this.isBusy = true;
        this.apiService.getTrades().subscribe((result) => this.trades = result.trades);
        this.apiService.getSocieties().subscribe((result) => this.societies = result.societies);
        this.apiService.getAffairs().subscribe((result) => this.affairs = result.affairs);
        this.activatedRoute.params.subscribe((params) => {
            if (params['id']) {
                this.apiService.getUser(params['id']).subscribe((user) => {
                    this.user = user;
                    this.user.affairs = [];
                    this.user.affairIds.forEach((id) => {
                        this.user.affairs.push(id);
                    });
                    this.createForm();
                });
            } else {
                this.user = new User();
                this.createForm();
            }
        });
    }

    createForm() {
        this.isBusy = false;
        this.user.roles = this.formatArray(this.user.roles);
        this.user.affairs = this.formatArray(this.user.affairs);
        this.user.contact.trades = this.formatArray(this.user.contact.trades);
        this.userForm = new FormGroup({
            civility: new FormControl(this.user.contact.civility),
            name: new FormControl(this.user.contact.name),
            surname: new FormControl(this.user.contact.surname),
            email: new FormControl(this.user.contact.email),
            phone: new FormControl(this.user.contact.phone),
            position: new FormControl(this.user.contact.position),
            trades: new FormControl(this.user.contact.trades),
            address: new FormControl(this.user.contact.address),
            postalCode: new FormControl(this.user.contact.postalCode),
            city: new FormControl(this.user.contact.city),
            country: new FormControl(this.user.contact.country),
            hasUser: new FormControl(1),
            society: new FormControl(this.user.contact.societyId),
        });
        if (this.user.id) {
            this.userForm.addControl('user', new FormGroup({
                roles: new FormControl(this.user.roles),
                affairs: new FormControl(this.user.affairs),
            }));
        } else {
            this.userForm.addControl('user', new FormGroup({
                // plainPassword: new FormGroup({
                //     first: new FormControl(),
                //     second: new FormControl(),
                // }, {validators: this.checkPasswords}),
                roles: new FormControl(this.user.roles),
                affairs: new FormControl(this.user.affairs),
            }));
        }

        this.matcher = new ErrorStateMatcher();
    }

    checkPasswords(group: FormGroup) { // here we have the 'passwords' group
        const first = group.get('first').value;
        const second = group.get('second').value;

        return first === second ? null : {notSame: true};
    }

    onSubmit(): void {
        if (this.userForm.valid) {
            this.isSaving = true;
            const data = this.userForm.value;
            data.trades = this.formatArray(data.trades);
            if (this.user.id && this.user.contact.id) {
                data.user.affairs = this.formatArray(data.user.affairs);
                this.apiService.putContact(this.user.contact.societyId, this.user.contact.id, this.userForm.value).subscribe((contact) => {
                    this.isSaving = false;
                    if (contact) {
                        this.toastService.show(`L'utilisateur "${contact.fullName}" a été modifié.`);
                        this.router.navigate(['annuaire']);
                    }
                }, () => this.isSaving = false);
            } else {
                data.user.affairs = this.formatArray(data.user.affairs);
                this.apiService.postContact(this.userForm.value, this.userForm.value.society).subscribe((contact) => {
                    this.isSaving = false;
                    if (contact) {
                        this.toastService.show(`L'utilisateur "${contact.fullName}" a été ajouté.`);
                        this.router.navigate(['annuaire']);
                    }
                }, () => this.isSaving = false);
            }
        }
    }
}
