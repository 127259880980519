import { Component, EventEmitter, Input, OnInit, Output, ViewContainerRef } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { ApiService } from '@src/app/services/api.service';
import { ModalService } from '@src/app/services/modal.service';
import { StorageService } from '@src/app/services/storage.service';
import {AnnuaireNavItems, AnnuaireViewsItems} from '@src/app/shared/interfaces';

@Component({
  selector: 'app-annuaire-nav',
  templateUrl: './annuaire-nav.component.html',
  styleUrls: ['./annuaire-nav.component.scss']
})
export class AnnuaireNavComponent implements OnInit {

  @Input() viewContainerRef: ViewContainerRef;
  @Input() back: any[];
  @Input() title: string;
  @Input() querySearch: any;
  @Input() isBusy: boolean;
  @Input() items: AnnuaireNavItems;
  @Output() viewEmitter = new EventEmitter<AnnuaireViewsItems>();
  roles: string[] = [];
  views: AnnuaireViewsItems = {
    isUser: true,
    isSociety: false,
    isTrade: false,
  };

  constructor(
    private router: Router,
    private activatedRoute: ActivatedRoute,
    private storageService: StorageService,
    private apiService: ApiService,
    private modalService: ModalService,
  ) { }

  ngOnInit(): void {
    this.roles = this.storageService.roles;

    this.activatedRoute.queryParams.subscribe((params) => {
      if (params['_w']) {
        this.onTapView(params['_w']);
      }
    });
  }

  hasRoles(roles: string[]): boolean {
    return roles.some((role) => this.roles.includes(role));
  }

  goBack(): void {
    this.router.navigate(this.back);
  }

  onTapView(page: string): void {
    switch (page) {
      case 'society':
        this.views.isUser = this.views.isTrade = false;
        this.views.isSociety = true;
        // console.log('views ->', this.views);
        break;
      case 'trade':
        this.views.isSociety = this.views.isUser = false;
        this.views.isTrade = true;
        // console.log('views ->', this.views);
        break;
      case 'user':
        this.views.isSociety = this.views.isTrade = false;
        this.views.isUser = true;
        // console.log('views ->', this.views);
        break;
    }
    this.viewEmitter.emit(this.views);
  }

  navigateToSearchPage(): void {
    this.router.navigate(['/rechercher'], {queryParams: this.querySearch});
  }

}
