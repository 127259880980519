<mat-sidenav-container class="home__container">
    <mat-sidenav class="feed-sidenav" mode="over" position="end" #feedSideNav>
        <div *ngIf="sideNavType == 'feed'" fxLayout="column" fxLayoutAlign="space-around">
            <div fxLayout="column" fxLayoutAlign="space-around end">
                <button mat-button (click)="feedSideNav.close()">Mes actualités <i
                        class="fas fa-angle-double-right"></i></button>
            </div>

            <div class="feed-list" fxLayout="column" fxLayoutAlign="space-around">
                <app-feed *ngFor="let feed of feedList$ | async" [data]="feed"></app-feed>
            </div>
        </div>

        <div *ngIf="sideNavType == 'affair'" fxLayout="column" fxLayoutAlign="space-around">
            <div fxLayout="column" fxLayoutAlign="space-around end">
                <button mat-button (click)="feedSideNav.close()">Affaires en cours <i
                        class="fas fa-angle-double-right"></i></button>
            </div>

            <div class="feed-list" fxLayout="column" fxLayoutAlign="space-around">
                <app-affair-side-nav *ngFor="let affair of affairList$ | async; let last = last" [data]="affair"
                                     [class.mb-3]="last" class="position-relative pt-3 px-3"></app-affair-side-nav>
            </div>
        </div>

        <div *ngIf="sideNavType == 'messages'" fxLayout="column" fxLayoutAlign="space-around">
            <div fxLayout="column" fxLayoutAlign="space-around end">
                <button mat-button (click)="feedSideNav.close()">Nouveaux messages <i
                        class="fas fa-angle-double-right"></i></button>
            </div>

            <div class="feed-list" fxLayout="column" fxLayoutAlign="space-around">
                <app-message-side-nav *ngFor="let message of messagesList$ | async; let last = last" [data]="message"
                                      [class.mb-3]="last"></app-message-side-nav>
            </div>
        </div>

        <div *ngIf="sideNavType == 'notification'" fxLayout="column" fxLayoutAlign="space-around">
            <div fxLayout="column" fxLayoutAlign="space-around end">
                <button mat-button (click)="feedSideNav.close()">Mes activités <i
                        class="fas fa-angle-double-right"></i></button>
            </div>

            <div class="feed-list" fxLayout="column" fxLayoutAlign="space-around">
                <app-notify-side-nav *ngFor="let notification of notificationList$ | async; let last = last"
                                     [data]="notification" [class.mb-3]="last"></app-notify-side-nav>
            </div>
        </div>

        <mat-spinner *ngIf="!isSideNavLoaded"></mat-spinner>
    </mat-sidenav>
    <mat-sidenav-content>
        <div class="container-fluid p-3">
            <div class="row">
                <div class="col-md-8">
                    <div class="bg-primary-light p-3 rounded">
                        <h1 class="text-primary mt-0">Bonjour {{ fullName }} !</h1>
                        <div class="row">
                            <div *ngIf="infos" class="col">
                                <div (click)="feedSideNav.open() && onAffairSideNavOpen()"
                                     class="d-flex flex-column justify-content-center align-items-center cursor-pointer text-green">
                                    <mat-icon class="icon-48">work</mat-icon>
                                    <span *ngIf="infos.affair === 0 "
                                          class="text-center">Aucune Affaire<br>en cours</span>
                                    <span *ngIf="infos.affair === 1" class="text-center">{{ infos.affair }} Affaire<br>en cours</span>
                                    <span *ngIf="infos.affair > 1" class="text-center">{{ infos.affair }} Affaires<br>en cours</span>
                                </div>
                            </div>
                            <div *ngIf="infos" class="col">
                                <div (click)="feedSideNav.open() && onMessagesSideNavOpen()"
                                     class="d-flex flex-column justify-content-center align-items-center cursor-pointer text-red">
                                    <mat-icon class="icon-48">chat</mat-icon>
                                    <span *ngIf="infos.messages === 0"
                                          class="text-center">Aucun nouveau<br>message</span>
                                    <span *ngIf="infos.messages === 1" class="text-center">{{ infos.messages }}
                                        Nouveau<br>message</span>
                                    <span *ngIf="infos.messages > 1" class="text-center">{{ infos.messages }}
                                        Nouveaux<br>messages</span>
                                </div>
                            </div>
<!--                            <div *ngIf="infos" class="col">-->
<!--                                <div (click)="feedSideNav.open() && onNotificationSideNavOpen()"-->
<!--                                     class="d-flex flex-column justify-content-center align-items-center cursor-pointer text-yellow">-->
<!--                                    <mat-icon class="icon-48">announcement</mat-icon>-->
<!--                                    <span *ngIf="infos.notif === 0" class="text-center">Aucune nouvelle<br>notification</span>-->
<!--                                    <span *ngIf="infos.notif === 1" class="text-center">{{ infos.notif }} Nouvelle<br>notification</span>-->
<!--                                    <span *ngIf="infos.notif > 1" class="text-center">{{ infos.notif }} Nouvelles<br>notifications</span>-->
<!--                                </div>-->
<!--                            </div>-->
                        </div>
                    </div>
                </div>
                <div class="col-md-4 pr-0">
                    <div>
                        <mat-form-field appearance="outline" class="align-self-end" [routerLink]="['/rechercher']">
                            <mat-label>Saisir ma recherche</mat-label>
                            <span matPrefix><mat-icon>search</mat-icon></span>
                            <input matInput>
                        </mat-form-field>
                    </div>
                    <div class="d-flex justify-content-end align-items-center">
                        <button mat-stroked-button color="primary" class="btn-outline-actualite"
                                (click)="feedSideNav.open() && onFeedSideNavOpen()">
                            <i class="fas fa-angle-double-left mr-2"></i>
                            <span>Mes actualités</span>
                        </button>
                    </div>
                    <div class="d-flex justify-content-end align-items-center mt-3">
                        <button mat-stroked-button color="primary" class="btn-outline-actualite"
                                (click)="feedSideNav.open() && onNotificationSideNavOpen()">
                            <i class="fas fa-angle-double-left mr-2"></i>
                            <span>Mes activités</span>
                        </button>
                    </div>
                </div>
            </div>

            <div>
                <h2 class="text-primary font-weight-normal">Mes projets</h2>
                <div *ngIf="isProjetBusy"><mat-spinner></mat-spinner></div>
                <div *ngIf="!isProjetBusy" class="row flex-nowrap overflow-auto">
                    <div *ngFor="let projet of projets;" class="col-12 col-sm-6 col-md-4 col-lg-3 mb-3">
                        <a [routerLink]="['/affaire', projet.id]"
                           class="d-flex flex-column h-100 text-left bg-primary-light rounded cursor-pointer p-2 text-decoration-none text-reset">
                            <div class="fz-18 font-weight-bold">{{projet.label}}</div>
                            <div>• Ref: {{projet.reference}}</div>
                            <div class="mb-2">{{projet.city}} {{projet.postalCode}}</div>
                            <div class="bg-white text-primary text-center rounded mt-auto p-2">{{projet.stepLabel.step.label}}</div>
                            <div class="affair-grid-progress">
                                <div *ngIf="projet.stepLabel.avancement[3].pourcent > 0" class="bg-green"
                                     [style]="'width: ' + projet.stepLabel.avancement[3].pourcent + '%'"></div>
                                <div *ngIf="projet.stepLabel.avancement[2].pourcent > 0" class="bg-yellow"
                                     [style]="'width: ' + projet.stepLabel.avancement[2].pourcent + '%'"></div>
                                <div *ngIf="projet.stepLabel.avancement[1].pourcent > 0" class="bg-red"
                                     [style]="'width: ' + projet.stepLabel.avancement[1].pourcent + '%'"></div>
                            </div>
                        </a>
                    </div>
                    <div *ngIf="!projets.length" class="col-12 d-flex align-items-center text-danger">
                        <mat-icon>report</mat-icon> Aucun projet en cours.
                    </div>
                </div>
            </div>
            <!-- div retournant uniquement les affaires en cours de chantier -->
            <div>
                <h2 class="text-primary font-weight-normal">Mes chantiers</h2>
                <div *ngIf="isChantierBusy"><mat-spinner></mat-spinner></div>
                <div *ngIf="!isChantierBusy" class="row flex-nowrap overflow-auto">
                    <div *ngFor="let chantier of chantiers;" class="col-12 col-sm-6 col-md-4 col-lg-3 mb-3">
                        <a [routerLink]="['/affaire', chantier.id]"
                           class="d-flex flex-column h-100 text-left bg-primary-light rounded cursor-pointer p-2 text-decoration-none text-reset">
                            <div class="fz-18 font-weight-bold">{{chantier.label}}</div>
                            <div>• Ref: {{chantier.reference}}</div>
                            <div class="mb-2">{{chantier.city}} {{chantier.postalCode}}</div>
                            <div class="bg-white text-primary text-center rounded mt-auto p-2">{{chantier.stepLabel.step.label}}</div>
                            <div class="affair-grid-progress">
                                <div *ngIf="chantier.stepLabel.avancement[3].pourcent > 0" class="bg-green"
                                     [style]="'width: ' + chantier.stepLabel.avancement[3].pourcent + '%'"></div>
                                <div *ngIf="chantier.stepLabel.avancement[2].pourcent > 0" class="bg-yellow"
                                     [style]="'width: ' + chantier.stepLabel.avancement[2].pourcent + '%'"></div>
                                <div *ngIf="chantier.stepLabel.avancement[1].pourcent > 0" class="bg-red"
                                     [style]="'width: ' + chantier.stepLabel.avancement[1].pourcent + '%'"></div>
                            </div>
                        </a>
                    </div>
                    <div *ngIf="!chantiers.length" class="col-12 d-flex align-items-center text-danger">
                        <mat-icon>report</mat-icon> Aucun chantier en cours.
                    </div>
                </div>
            </div>

            <div>
                <app-planning></app-planning>
            </div>
        </div>
    </mat-sidenav-content>
</mat-sidenav-container>
