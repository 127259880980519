<div class="d-flex flex-column">
    <app-affair-nav [affair]="affair" [back]="back" [title]="isBusy ? title : 'Liste des affaires'"
                    [querySearch]="querySearch" [isBusy]="isBusy" [items]="items"
                    (viewEmitter)="viewHandler($event)"
                    class="d-flex flex-row justify-content-between align-items-center bg-primary-light p-3">
    </app-affair-nav>
    <app-step-global [isVisible]="views.isGlobalView" [viewContainerRef]="viewContainerRef"></app-step-global>
    <app-step-etape [isVisible]="views.isEtapeView" [viewContainerRef]="viewContainerRef"></app-step-etape>
    <app-step-calendar [isVisible]="views.isCalendarView" [viewContainerRef]="viewContainerRef"></app-step-calendar>
</div>
