import {Component, Input, OnChanges, OnInit, ViewContainerRef} from '@angular/core';
import {Router} from '@angular/router';
import {ApiService} from '@src/app/services/api.service';
import {ToastService} from '@src/app/services/toast.service';
import {AlertService} from '@src/app/services/alert.service';
import {User} from '../../model/user.model';
import {ActionBottomSheetService} from '@src/app/services/action-bottom-sheet.service';
import {ExternalAppService} from '@src/app/services/external-app.service';
import {ModalService} from '@src/app/services/modal.service';
import { SimpleChanges } from '@src/app/shared/interfaces';

@Component({
    selector: 'app-annuaire-user',
    templateUrl: './annuaire-user.component.html',
    styleUrls: ['./annuaire-user.component.scss'],
    providers: [ActionBottomSheetService]
})
export class AnnuaireUserComponent implements OnInit {

    @Input() isVisible: boolean;
    @Input() viewContainerRef: ViewContainerRef;
    title = 'Utilisateurs';
    mode = 'over';
    backdrop = true;
    users: User[];
    usersList: User[];
    isBusy: boolean;
    search: string;

    constructor(
        private router: Router,
        private apiService: ApiService,
        private toastService: ToastService,
        private alertService: AlertService,
        private toggleBottomSheetService: ActionBottomSheetService,
        private externalAppService: ExternalAppService,
        private modalService: ModalService,
    ) { }

    ngOnInit(): void {
        // this.isBusy = true;

        this.getUser();
    }

    // ngOnChanges(changes: SimpleChanges) {
        // if (
        //     changes.isVisible.currentValue
        //     && !changes.isVisible.previousValue
        //     && typeof changes.isVisible.previousValue !== 'undefined'
        // ) {
        //     this.getUser();
        // }
    // }

    getUser() {
        this.apiService.getUsers().subscribe((result) => {
            this.usersList = result.users;
            this.users = result.users;
            // this.isBusy = false;
        });
    }

    onSearch() {
        this.users = this.usersList.filter((user) => {
            return user.fullName.toLowerCase().indexOf(this.search.toLowerCase()) >= 0;
        });
    }

    clearSearch() {
        this.search = '';
        this.users = this.usersList;
    }

    onTapAdd() {
        this.router.navigate(['annuaire', 'user', 'edit']);
    }

    onTapAddModal() {
        this.modalService.show(
            {
                type: 'user-add'
            },
            'base-modal',
            this.viewContainerRef,
            true
        ).subscribe((result) => {
            if (result) {
                this.users.unshift(result);
            }
        });
    }

    onTapPhone(user: User) {
        this.externalAppService.phone(user.contact.phone);
    }

    onTapEmail(user: User) {
        this.externalAppService.email(user.contact.email);
    }

    onTapEdit(user: User) {
        this.router.navigate(['annuaire', 'user', 'edit', user.id]);
    }

    onTapEditModal(user: User) {
        this.modalService.show(
            {
                id: user.id,
                type: 'user-edit',
            },
            'base-modal',
            this.viewContainerRef,
            true
        ).subscribe((result) => {
            if (result) {
                this.users = this.users.map(u => u.id === result.id ? result : u);
            }
        });
    }

    onTapDelete(user: User) {
        const text = `Vous allez définitivement supprimer un utilisateur.
        Vous ne pourrez pas récupérer cet utilisateur !`;
        this.alertService.show(text).then((result) => {
            if (result.isConfirmed) {
                this.apiService.deleteUser(user.id).subscribe(() => {
                    this.toastService.show(`L'utilisateur "${user.fullName}" a bien été supprimer.`);
                    this.users = this.users.filter(u => u.id !== user.id);
                });
            }
        });
    }

    onTapMore(user: User) {
        this.toggleBottomSheetService.showBottomSheet(
            'annuaire',
            user.contact,
            {},
            this.viewContainerRef
        ).subscribe(result => {
            if (result && result.action) {
                setTimeout(() => {
                    switch (result.action) {
                        case 'onTapPhone':
                            this.onTapPhone(user);
                            break;
                        case 'onTapEmail':
                            this.onTapEmail(user);
                            break;
                        case 'onTapEdit':
                            this.onTapEditModal(user);
                            break;
                        case 'onTapDelete':
                            this.onTapDelete(user);
                            break;
                    }
                }, 300);
            }
        });
    }
}
