<div *ngIf="isVisible">
    <div *ngIf="!isBusy || affair?.affairSteps" class="bg-primary-light rounded-bottom mb-3">
        <div *ngIf="affair && !hasRoles(['ROLE_GUEST'])" class="d-flex flex-row justify-content-between align-content-center overflow-auto px-3 pb-3 ">
            <div *ngFor="let affairStep of affair.affairSteps; let index = index;" class="mx-2">
                <button *ngIf="affair.isChargeAffair && affairStep.active == 1 && activeStep == index" (click)="goToStep(index)"
                        mat-stroked-button style="width: 200px"
                        class="mat-stroked-button-icon btn-rounded-sm bg-primary text-white" [matMenuTriggerFor]="stepMenu"
                        [matMenuTriggerData]="{step: affairStep}"
                        [matTooltip]="affairStep.date ? formatDate(affairStep.date) : ''" matTooltipPosition="below">
                    <span>{{affairStep.step.label}}</span>
                    <mat-icon class="ml-1">more_vert</mat-icon>
                </button>
                <button *ngIf="affair.isChargeAffair && affairStep.active == 1 && activeStep != index" (click)="goToStep(index)"
                        mat-stroked-button style="width: 200px"
                        class="mat-stroked-button-icon btn-rounded-sm"
                        [matTooltip]="affairStep.date ? formatDate(affairStep.date) : ''" matTooltipPosition="below">
                    <span>{{affairStep.step.label}}</span>
                </button>
                <button *ngIf="!affair.isChargeAffair && affairStep.active == 1" (click)="goToStep(index)"
                        [class]="activeStep == index ? 'bg-primary text-white' : ''" mat-stroked-button style="width: 200px"
                        class="btn-rounded-sm"
                        [matTooltip]="affairStep.date ? formatDate(affairStep.date) : ''" matTooltipPosition="below">{{affairStep.step.label}}</button>

                <button *ngIf="affairStep.active == 0" (click)="goToStep(index)"
                        [class]="activeStep == index ? 'bg-primary text-white' : ''" mat-stroked-button style="width: 200px"
                        class="btn-rounded-sm"
                        [matTooltip]="affairStep.date ? formatDate(affairStep.date) : ''" matTooltipPosition="below">{{affairStep.step.label}}</button>

                <button *ngIf="affair.isChargeAffair && affairStep.active == -1" (click)="goToStep(index)"
                        [class]="activeStep == index ? 'bg-primary text-white' : ''" mat-stroked-button
                        style="width: 200px" class="btn-rounded-sm"
                        [matTooltip]="affairStep.date ? formatDate(affairStep.date) : ''" matTooltipPosition="below">{{affairStep.step.label}}</button>
                <button *ngIf="!affair.isChargeAffair && affairStep.active == -1" mat-stroked-button disabled
                        style="width: 200px" class="btn-rounded-sm"
                        [matTooltip]="affairStep.date ? formatDate(affairStep.date) : ''" matTooltipPosition="below">{{affairStep.step.label}}</button>

                <div *ngIf="affairStep.active >= 0" class="affair-grid-progress">
                    <div *ngIf="affairStep.avancement[3].pourcent > 0" class="bg-green"
                            [style]="'width: ' + affairStep.avancement[3].pourcent + '%'"></div>
                    <div *ngIf="affairStep.avancement[2].pourcent > 0" class="bg-yellow"
                            [style]="'width: ' + affairStep.avancement[2].pourcent + '%'"></div>
                    <div *ngIf="affairStep.avancement[1].pourcent > 0" class="bg-red"
                            [style]="'width: ' + affairStep.avancement[1].pourcent + '%'"></div>
                </div>
            </div>
            <mat-menu #stepMenu="matMenu" xPosition="before">
                <ng-template matMenuContent let-step="step">
                    <button *ngIf="step.step.id < 7" mat-menu-item (click)="onTapStepValid(step)">
                        <mat-icon class="text-success">done_all</mat-icon>
                        <span>Valider</span>
                    </button>
                    <button *ngIf="step.step.id > 1" mat-menu-item (click)="onTapStepRefuse(step)">
                        <mat-icon class="text-danger">undo</mat-icon>
                        <span>Annuler</span>
                    </button>
                </ng-template>
            </mat-menu>
        </div>
    </div>

    <div *ngIf="isBusy" class="d-flex justify-content-center align-content-center mt-3">
        <mat-progress-spinner mode="indeterminate"></mat-progress-spinner>
    </div>

    <!-- création due la vue par étapes de l'affaire -->
    <div *ngIf="!isBusy" class="container-fluid">
        <div *ngIf="affair" cdkDropListGroup [cdkDropListGroupDisabled]="hasRoles(['ROLE_GUEST']) || !affair.allowTo.drag" class="row pb-3">
            <div *ngFor="let statut of [1,2,3]; let index = index" class="col-12 col-lg-4 d-flex mb-3">
                <div class="p-3 bg-primary-light rounded d-flex flex-column flex-fill">
                    <div class="d-flex flex-row ">
                        <div [class]="'bg-' + statusLabel[statut].class"
                                class="d-flex flex-row flex-fill justify-content-between align-content-center text-white rounded p-3">
                            <div>{{statusLabel[statut].label}}</div>
                            <div>{{affair.affairSteps[activeStep].avancement[statut].pourcent + '%'}}</div>
                        </div>
                        <mat-icon matRipple *ngIf="affair.isChargeAffair" (click)="onTapAddDossier(statut)"
                                    [class]="'text-' + statusLabel[statut].class + ' border-' +  statusLabel[statut].class + ' has-ripple-' +  statusLabel[statut].class"
                                    matTooltip="Ajouter un dossier" matTooltipPosition="above"
                                    class="icon-30 ml-3 cursor-pointer has-ripple">add
                        </mat-icon>
                    </div>

                    <div cdkDropList [cdkDropListData]="statut" (cdkDropListDropped)="drop($event)" class="d-flex flex-column flex-fill">
                        <app-etape-dossier *ngFor="let state of sortByDossier(statut); trackBy: trackBy;" (affairUpdate)="affairUpdateHandle($event)"
                                           [dossier]="state" [affair]="affair" [activeStep]="activeStep" [viewContainerRef]="viewContainerRef"
                                           cdkDrag [cdkDragData]="state" [dropState]="isDossierBusy[state.id]">
                        </app-etape-dossier>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>
