import {Component, OnInit, ViewContainerRef} from '@angular/core';
import {ActivatedRoute, Router} from '@angular/router';
import {ApiService} from '@src/app/services/api.service';
import {ScrollViewService} from '@src/app/services/scroll-view.service';
import {ActionBottomSheetService} from '@src/app/services/action-bottom-sheet.service';
import {Affair} from '@src/app/model/affair.model';
import {AffairNavItems, AffairViewsItems} from '@src/app/shared/interfaces';
import {BaseComponent} from '@src/app/shared/base.component';
import {MatDrawerMode} from '@angular/material/sidenav';

@Component({
    selector: 'app-affair-step',
    templateUrl: './affair-step.component.html',
    styleUrls: ['./affair-step.component.scss'],
    providers: [ActionBottomSheetService]
})
export class AffairStepComponent extends BaseComponent implements OnInit {
    title = 'Chargement...';
    mode: MatDrawerMode = 'over';
    backdrop = true;
    isBusy: boolean;
    affair: Affair;
    activeStep: any;
    views: AffairViewsItems = {
        isGlobalView: false,
        isEtapeView: true,
        isCalendarView: false,
    };
    back: any[];
    querySearch: any;
    items: AffairNavItems = {
        back: false,
        state: false,
        views: true,
        collaboratif: true,
        equipe: true,
    };

    constructor(
        private scrollViewService: ScrollViewService,
        private apiService: ApiService,
        private router: Router,
        private activatedRoute: ActivatedRoute,
        public viewContainerRef: ViewContainerRef,
    ) {
        super();
        this.sharedService.updateTitle(this.title);
        this.sharedService.updateMode(this.mode);
        this.sharedService.updateBackdrop(this.backdrop);
    }

    ngOnInit(): void {
        this.isBusy = true;
        this.items.back = !this.hasRoles(['ROLE_GUEST']);
        this.activatedRoute.params.subscribe((params) => {
            this.apiService.getAffairLight(params['id']).subscribe((affair: Affair) => {
                this.affair = affair;
                this.title  = affair.label + ' - ' + affair.reference;
                this.sharedService.updateTitle(this.title);
                this.back = ['/affaires'];
                this.querySearch = {affair: this.affair.id, step: this.affair.currentStep.id};
                this.isBusy = false;
            });
        });
    }

    viewHandler(views: AffairViewsItems): void {
        this.views = views;
    }
}
