import {Routes} from '@angular/router';
import {HomeComponent} from '@src/app/home/home.component';
import {AffairListComponent} from '@src/app/affair/list/affair-list.component';
import {SpecificComponent} from '@src/app/affair/specific/specific.component';
import {SpecificDetailComponent} from '@src/app/affair/specific-detail/specific-detail.component';
import {LoginComponent} from '@src/app/user/login/login.component';
import {IsSignedInGuard} from '@src/app/shared/is-signed-in.guard';
import {AnnuaireComponent} from '@src/app/annuaire/annuaire.component';
import {AffairStepComponent} from '@src/app/affair/step/affair-step.component';
import {AffairDossierComponent} from '@src/app/affair/affair-dossier/affair-dossier.component';
import {UploadFileComponent} from '@src/app/affair/upload-file/upload-file.component';
import {SearchComponent} from '@src/app/search/search.component';
import {ViewerComponent} from '@src/app/affair/affair-dossier/viewer/viewer.component';
import {AccountComponent} from '@src/app/user/account/account.component';
import {GuestComponent} from '@src/app/guest/guest.component';
import {ResetPasswordComponent} from '@src/app/user/reset-password/reset-password.component';
import {ResetPasswordConfirmComponent} from '@src/app/user/reset-password-confirm/reset-password-confirm.component';

export const routes: Routes = [
    {
        path: '',
        redirectTo: '/home',
        // redirectTo: '/affaires',
        // redirectTo: '/affaire/33',
        // redirectTo: '/affair-dossier/2514',
        // redirectTo: '/specific/2930',
        // redirectTo: '/specific-detail/3083',
        pathMatch: 'full',
        canActivate: [IsSignedInGuard],
    },
    {
        path: 'home',
        component: HomeComponent,
        canActivate: [IsSignedInGuard],
    },
    {
        path: 'affaires',
        component: AffairListComponent,
        canActivate: [IsSignedInGuard],
    },
    {
        path: 'affaire/:id',
        component: AffairStepComponent,
        canActivate: [IsSignedInGuard],
    },
    {
        path: 'affaire/:id/:view',
        component: AffairStepComponent,
        canActivate: [IsSignedInGuard],
    },
    {
        path: 'specific/:id',
        component: SpecificComponent,
        canActivate: [IsSignedInGuard],
    },
    {
        path: 'specific-detail/:id',
        component: SpecificDetailComponent,
        canActivate: [IsSignedInGuard],
    },
    {
        path: 'specific-detail/:id/:user',
        component: SpecificDetailComponent,
        canActivate: [IsSignedInGuard],
    },
    {
        path: 'affair-dossier/:id',
        component: AffairDossierComponent,
        canActivate: [IsSignedInGuard],
    },
    {
        path: 'affair-dossier/:id/:preview',
        component: AffairDossierComponent,
        canActivate: [IsSignedInGuard],
    },
    {
        path: 'affair-dossier/:id/ajouter/:documentId/:fileName',
        component: UploadFileComponent,
        canActivate: [IsSignedInGuard],
    },
    {
        path: 'affair-document/:id',
        component: ViewerComponent,
        canActivate: [IsSignedInGuard],
    },
    {
        path: 'annuaire',
        component: AnnuaireComponent,
        canActivate: [IsSignedInGuard],
        children: [
            {
                path: 'society',
                component: AnnuaireComponent,
            },
            {
                path: 'trade',
                component: AnnuaireComponent,
            },
            {
                path: 'user',
                component: AnnuaireComponent,
            },
        ]
    },
    {
        path: 'login',
        component: LoginComponent,
    },
    {
        path: 'reset-password',
        component: ResetPasswordComponent,
    },
    {
        path: 'reset-password/confirm/:token',
        component: ResetPasswordConfirmComponent,
    },
    {
        path: 'guest/:dossier/:token',
        component: GuestComponent,
    },
    {
        path: 'rechercher',
        component: SearchComponent,
        canActivate: [IsSignedInGuard],
    },
    {
        path: 'account',
        component: AccountComponent,
        canActivate: [IsSignedInGuard],
    },
];
