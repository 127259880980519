import {Component, Input, OnChanges, OnInit, ViewContainerRef} from '@angular/core';
import {Router} from '@angular/router';
import {ApiService} from '@src/app/services/api.service';
import {ToastService} from '@src/app/services/toast.service';
import {AlertService} from '@src/app/services/alert.service';
import {Society} from '@src/app/model/society.model';
import {ActionBottomSheetService} from '@src/app/services/action-bottom-sheet.service';
import {ExternalAppService} from '@src/app/services/external-app.service';
import {ModalService} from '@src/app/services/modal.service';
import { SimpleChanges } from '@src/app/shared/interfaces';

@Component({
    selector: 'app-annuaire-societe',
    templateUrl: './annuaire-society.component.html',
    styleUrls: ['./annuaire-society.component.scss'],
    providers: [ActionBottomSheetService]
})
export class AnnuaireSocietyComponent implements OnInit {

    @Input() isVisible: boolean;
    @Input() viewContainerRef: ViewContainerRef;
    title = 'Sociétés';
    mode = 'over';
    backdrop = true;
    societies: Society[];
    societiesList: Society[];
    isBusy: boolean;
    search: string;


    constructor(
        private router: Router,
        private apiService: ApiService,
        private toastService: ToastService,
        private alertService: AlertService,
        private toggleBottomSheetService: ActionBottomSheetService,
        private externalAppService: ExternalAppService,
        private modalService: ModalService,
    ) { }

    ngOnInit(): void {
        // this.isBusy = true;

        this.getSociety();
    }

    // ngOnChanges(changes: SimpleChanges) {
        // if (
        //     changes.isVisible.currentValue
        //     && !changes.isVisible.previousValue
        //     && typeof changes.isVisible.previousValue !== 'undefined'
        // ) {
        //     this.getSociety();
        // }
    // }

    getSociety() {
        this.apiService.getSocieties().subscribe((societies) => {
            this.societiesList = societies.societies;
            this.societies = societies.societies;
            // this.isBusy = false;
        });
    }

    onSearch() {
        this.societies = this.societiesList.filter((society) => {
            return society.name.toLowerCase().indexOf(this.search.toLowerCase()) >= 0;
        });
    }

    clearSearch() {
        this.search = '';
        this.societies = this.societiesList;
    }

    onTapAdd() {
        this.router.navigate(['annuaire', 'society', 'edit']);
    }

    onTapAddModal() {
        this.modalService.show(
            {
                type: 'society-add'
            },
            'base-modal',
            this.viewContainerRef,
            true
        ).subscribe((result) => {
            if (result) {
                this.societies.unshift(result);
            }
        });
    }

    onTapPhone(society: Society) {
        this.externalAppService.phone(society.phone);
    }

    onTapEmail(society: Society) {
        this.externalAppService.email(society.email);
    }

    onTapEdit(society: Society) {
        this.router.navigate(['annuaire', 'society', 'edit', society.id]);
    }

    onTapEditModal(society: Society) {
        this.modalService.show(
            {
                id: society.id,
                type: 'society-edit',
            },
            'base-modal',
            this.viewContainerRef,
            true
        ).subscribe((result) => {
            if (result) {
                this.societies = this.societies.map(s => s.id === result.id ? result : s);
            }
        });
    }

    onTapDelete(society: Society) {
        const test = `Vous allez définitivement supprimer une entreprise.
        Cela supprimera aussi tous les contacts rattachés à cette entreprise.
        Vous ne pourrez pas récupérer cette entreprise !`;
        this.alertService.show(test).then((result) => {
            if (result.isConfirmed) {
                this.apiService.deleteSociety(society.id).subscribe(() => {
                    this.toastService.show(`L'entreprise "${society.name}" a bien été supprimer.`);
                    this.societies = this.societies.filter(s => s.id !== society.id);
                });
            }
        });
    }

    onTapMore(society: Society) {
        this.toggleBottomSheetService.showBottomSheet(
            'annuaire',
            society,
            {},
            this.viewContainerRef
        ).subscribe(result => {
            if (result && result.action) {
                setTimeout(() => {
                    switch (result.action) {
                        case 'onTapPhone':
                            this.onTapPhone(society);
                            break;
                        case 'onTapEmail':
                            this.onTapEmail(society);
                            break;
                        case 'onTapEdit':
                            this.onTapEditModal(society);
                            break;
                        case 'onTapDelete':
                            this.onTapDelete(society);
                            break;
                    }
                }, 300);
            }
        });
    }

    trackByFn(index, item) {
        return index;
    }
}
