import {Component, Inject, OnInit} from '@angular/core';
import {MatDialogRef, MAT_DIALOG_DATA} from '@angular/material/dialog';
import {ApiService} from '@src/app/services/api.service';
import {ToastService} from '@src/app/services/toast.service';
import {DocumentHistorique} from '@src/app/model/document-historique.model';

@Component({
    selector: 'app-modal-rename',
    templateUrl: 'modal-rename.component.html',
})
export class ModalRenameComponent implements OnInit {

    name: string;
    isLoading: boolean;
    isSaving: boolean;

    constructor(
        public dialogRef: MatDialogRef<ModalRenameComponent>,
        @Inject(MAT_DIALOG_DATA) public documentHistorique: DocumentHistorique,
        private apiService: ApiService,
        private toastService: ToastService,
    ) {
        this.isLoading = true;
        this.isSaving = false;
    }

    ngOnInit(): void {
        this.name = this.documentHistorique.name;
        this.isLoading = false;
    }

    onSubmit(): void {
        this.isSaving = true;
        this.apiService.renameDocument(this.documentHistorique.id, this.name).subscribe(() => {
            this.documentHistorique.name = this.name;
            this.toastService.show(`Le nom du document "${this.documentHistorique.name}" a été modifié.`);
            this.isSaving = false;
            this.dialogRef.close();
        }, () => this.isSaving = false );
    }
}
