import {Component, OnInit} from '@angular/core';

@Component({
    selector: 'app-share-options',
    templateUrl: 'share-options.component.html'
})
export class ShareOptionsComponent implements OnInit {
    options: any[];

    constructor() {
    }

    ngOnInit() {
    }
}
