<div class="d-flex flex-column">
    <div class="d-flex flex-row justify-content-between align-items-center p-3">
        <div class="d-flex">
            <button (click)="onTapAdd()" mat-stroked-button color="primary" class="btn-rounded">
                <mat-icon>add</mat-icon>
                Ajouter une société
            </button>
        </div>

        <mat-form-field appearance="outline" class="pb-0">
            <mat-label>Saisir ma recherche</mat-label>
            <span matPrefix><mat-icon>search</mat-icon></span>
            <input matInput type="search" (keyup.enter)="onSearch()" [(ngModel)]="search">
            <mat-icon matSuffix (click)="clearSearch()" class="cursor-pointer">close</mat-icon>
        </mat-form-field>
    </div>

    <div fxFlex fxLayoutAlign="center center" *ngIf="isBusy" class="p-15">
        <mat-progress-spinner mode="indeterminate"></mat-progress-spinner>
    </div>

    <!-- nouvelle liste -->
    <div *ngFor="let society of societies" class="d-flex flex-row justify-content-start align-items-center text-left bg-primary-light rounded mx-3 mb-3 pl-3 py-1">
        <div (click)="onTapEdit(society)" matTooltip="Modifier la société" matTooltipPosition="above" class="d-flex flex-row justify-content-start align-items-center cursor-pointer w-100">
            <div class="d-flex flex-row align-items-center">
                <mat-icon>business</mat-icon>
                <span class="ml-1 mr-3">{{society.name}}</span>
                <span class="chip__blue fz-12">
                    {{society.contacts.length}}
                    Contact{{society.contacts.length > 1 ? 's' : ''}}
                </span>
            </div>
            <div class="ml-5">
                <span *ngFor="let trade of society.trades" class="chip__grey mx-1 fz-12">
                    {{trade.label}}
                </span>
            </div>
        </div>
        <button mat-icon-button [matMenuTriggerFor]="menuSociety" [matMenuTriggerData]="{society: society}"
                matTooltip="Menu édition" matTooltipPosition="above" class="ml-auto">
            <mat-icon>more_vert</mat-icon>
        </button>
    </div>
    <!-- ancienne liste -->
    <!-- <div *ngFor="let society of societies" fxFlex fxLayout="row" fxLayoutAlign="start center"
         class="user-grid-wrapper text-left bg-primary-light">
        <mat-icon class="user-grid-icon">business</mat-icon>
        <div class="d-flex flex-column flex-fill cursor-pointer" (click)="onTapEdit(society)">
            <div>{{society.name}} <span class="badge-pill badge-pill-light">{{society.contacts.length}}
                Contact{{society.contacts.length > 1 ? 's' : ''}}</span></div>
            <div><span *ngFor="let trade of society.trades"
                       class="badge-pill badge-pill-primary mr-1">{{trade.label}}</span></div>
        </div>

        <button mat-icon-button [matMenuTriggerFor]="menuSociety" [matMenuTriggerData]="{society: society}"
                class="ml-auto">
            <mat-icon>more_vert</mat-icon>
        </button>
    </div> -->

    <!-- menu édition société -->
    <mat-menu #menuSociety="matMenu" xPosition="before">
        <ng-template matMenuContent let-society="society">
            <a *ngIf="society.phone" mat-menu-item href="tel:{{society.phone}}">
                <mat-icon>phone</mat-icon>
                <span>{{society.phone}}</span>
            </a>
            <a *ngIf="society.email" mat-menu-item href="mailto:{{society.email}}">
                <mat-icon>mail_outline</mat-icon>
                <span>{{society.email}}</span>
            </a>
            <a *ngIf="society.contacts.length >= 1" mat-menu-item>
                <mat-icon>people_alt</mat-icon>
                <span>Liste des contacts</span>
            </a>
            <button mat-menu-item (click)="onTapEdit(society)">
                <mat-icon>edit</mat-icon>
                <span>Modifier</span>
            </button>
            <button mat-menu-item (click)="onTapDelete(society)">
                <mat-icon class="text-danger">delete_outline</mat-icon>
                <span>Supprimer</span>
            </button>
        </ng-template>
    </mat-menu>
</div>
