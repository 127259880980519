<!-- <mat-tab-group mat-stretch-tabs animationDuration="0ms" fxFlex fxLayout="column" fxFlexFill fxGrow>
    <mat-tab>
        <ng-template mat-tab-label>
            <mat-icon class="tab-icon">business</mat-icon>
            Sociétés
        </ng-template>
        <ng-template matTabContent>
            <app-annuaire-societe fxFlex></app-annuaire-societe>
        </ng-template>
    </mat-tab>
    <mat-tab>
        <ng-template mat-tab-label>
            <mat-icon class="tab-icon">construction</mat-icon>
            Corps d'état
        </ng-template>
        <ng-template matTabContent>
            <app-annuaire-trade></app-annuaire-trade>
        </ng-template>
    </mat-tab>
    <mat-tab>
        <ng-template mat-tab-label>
            <mat-icon class="tab-icon">people_alt</mat-icon>
            Utilisateurs
        </ng-template>
        <ng-template matTabContent>
            <app-annuaire-user></app-annuaire-user>
        </ng-template>
    </mat-tab>
</mat-tab-group> -->

<div class="d-flex flex-column">
    <app-annuaire-nav [back]="back" [title]="isBusy ? title : 'Annuaires'"
    [querySearch]="querySearch" [isBusy]="isBusy" [items]="items"
    (viewEmitter)="viewHandler($event)" class="d-flex flex-row justify-content-between align-items-center bg-primary-light p-3">
    </app-annuaire-nav>

    <app-annuaire-societe *ngIf="views.isSociety" [viewContainerRef]="viewContainerRef"></app-annuaire-societe>

    <app-annuaire-trade *ngIf="views.isTrade" [viewContainerRef]="viewContainerRef"></app-annuaire-trade>

    <app-annuaire-user *ngIf="views.isUser" [viewContainerRef]="viewContainerRef"></app-annuaire-user>

</div>
