import {Component, OnInit, ViewContainerRef} from '@angular/core';
import {ActivatedRoute, Router} from '@angular/router';
import {FormGroup, FormControl} from '@angular/forms';
import {ApiService} from '@src/app/services/api.service';
import {ToastService} from '@src/app/services/toast.service';
import {Trade} from '@src/app/model/trade.model';
import {BaseComponent} from '@src/app/shared/base.component';
import {MatDrawerMode} from '@angular/material/sidenav';

@Component({
    selector: 'app-annuaire-trade-form',
    templateUrl: './annuaire-trade-form.component.html',
    styleUrls: ['./annuaire-trade-form.component.scss'],
})
export class AnnuaireTradeFormComponent extends BaseComponent implements OnInit {
    title = 'Ajouter un corps d\'état';
    mode: MatDrawerMode = 'over';
    backdrop = true;
    isBusy: boolean;
    trade: Trade;
    tradeForm: FormGroup;
    isSaving = false;

    constructor(
        private router: Router,
        private apiService: ApiService,
        private activatedRoute: ActivatedRoute,
        private toastService: ToastService,
        public viewContainerRef: ViewContainerRef,
    ) {
        super();
        this.sharedService.updateTitle(this.title);
        this.sharedService.updateMode(this.mode);
        this.sharedService.updateBackdrop(this.backdrop);
        this.activatedRoute.params.subscribe((params) => {
            if (params['id']) {
                this.title = 'Modifier un corps d\'état';
                this.sharedService.updateTitle(this.title);
            }
        });

    }

    ngOnInit(): void {
        this.isBusy = true;
        this.activatedRoute.params.subscribe((params) => {
            if (params['id']) {
                this.apiService.getTrade(params['id']).subscribe((trade) => {
                    this.trade = trade;
                    this.createForm();
                });
            } else {
                this.trade = new Trade();
                this.createForm();
            }
        });
    }

    createForm() {
        this.isBusy = false;
        this.tradeForm = new FormGroup({
            label: new FormControl(this.trade.label),
        });
    }

    onSubmit(): void {
        if (this.tradeForm.valid) {
            this.isSaving = true;
            if (this.trade.id) {
                this.apiService.putTrade(this.trade.id, this.tradeForm.value).subscribe((trade) => {
                    this.isSaving = false;
                    if (trade) {
                        this.toastService.show(`Le corps d'état "${trade.label}" a été modifié.`);
                        this.router.navigate(['annuaire']);
                    }
                }, () => this.isSaving = false);
            } else {
                this.apiService.postTrade(this.tradeForm.value).subscribe((trade) => {
                    this.isSaving = false;
                    if (trade) {
                        this.toastService.show(`Le corps d'état "${trade.label}" a été ajouté.`);
                        this.router.navigate(['annuaire']);
                    }
                }, () => this.isSaving = false);
            }
        }
    }
}
