import {Component, Inject, OnInit} from '@angular/core';
import {MatDialogRef, MAT_DIALOG_DATA} from '@angular/material/dialog';
import {FormControl, Validators} from '@angular/forms';
import {ApiService} from '@src/app/services/api.service';
import {ToastService} from '@src/app/services/toast.service';
import * as moment from 'moment';
import {DocumentHistorique} from '@src/app/model/document-historique.model';
import { switchMap } from 'rxjs/operators';
import { environment } from '@src/environments/environment';

@Component({
    selector: 'app-modal-share',
    templateUrl: 'modal-share.component.html',
})
export class ModalShareComponent implements OnInit {

    apiUrl = environment.apiUrl;
    isLoading: boolean;
    isSaving: boolean;
    message: string;
    emails: string[];
    formControlEmail: FormControl;
    filteredEmails: string[];

    constructor(
        @Inject(MAT_DIALOG_DATA) public documentHistorique: DocumentHistorique,
        public dialogRef: MatDialogRef<ModalShareComponent>,
        private apiService: ApiService,
        private toastService: ToastService,
    ) {
        this.isLoading = true;
        this.isSaving = false;
    }

    ngOnInit(): void {
        this.emails = [];
        this.isLoading = false;
        this.filteredEmails = [];

        this.formControlEmail = new FormControl('', [Validators.email]);

        this.formControlEmail.valueChanges.pipe(
            switchMap(value => {
                return this.apiService.getUsersEmail({filter: {name: value}});
            })
        ).subscribe(emails => this.filteredEmails = emails);
    }

    compareList(c1: { id: number }, c2: { id: number }) {
        return c1 && c2 && c1.id === c2.id;
    }

    compareArray(otherArray) {
        return function (current) {
            return otherArray.filter(function (other) {
                return other.id === current.id && other.name === current.name;
            }).length === 0;
        };
    }

    addEmail() {
        if (this.formControlEmail.valid && this.formControlEmail.value) {
            if (this.emails.indexOf(this.formControlEmail.value)) {
                this.emails.push(this.formControlEmail.value);
            }
            this.formControlEmail.patchValue('');
        }
    }

    removeEmail(index: number) {
        this.emails.splice(index, 1);
    }

    onSubmit(): void {
        if (this.emails.length > 0) {
            this.isSaving = true;
            const data = {
                emails: this.emails,
                message: this.message,
            };
            this.apiService.postShareDocument(this.documentHistorique.id, data).subscribe(() => {
                this.isSaving = false;
                this.toastService.show(`Le partage du document "${this.documentHistorique.name}" a été éffectué.`);
                this.dialogRef.close();
            });
        }
    }

    // on vérifie que email retourné en est bien un et sous forme de string.
    displayFn(email: string): string {
      return email ? email : '';
    }

    private formatDate(date: any) {
        if (date) {
            if (typeof date === 'object') {
                return date.format('DD/MM/YYYY');
            } else {
                return moment(date).format('DD/MM/YYYY');
            }
        }
        return null;
    }

}
