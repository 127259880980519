<div class="d-flex flex-row align-items-center">
    <a *ngIf="items.back" mat-button class="btn-rounded has-ripple" [routerLink]="back">
        <mat-icon color="primary">arrow_back_ios</mat-icon>
        <span class="fz-18 d-none d-lg-inline-block">{{title}}</span>
    </a>
    <button *ngIf="items.state && dossier && !hasRoles(['ROLE_GUEST']) && ((dossier.allowTo.edit && dossier.code != 'CHIFFRAGE_1') || dossier.isChargeAffair)" [matMenuTriggerFor]="stateMenu"
            [matMenuTriggerData]="{dossier: dossier}" mat-flat-button
            [class]="'bg-' + statusLabel[dossier.state].class" class="ml-3 text-white">
        <span class="d-none d-lg-inline-block mr-1">{{statusLabel[dossier.state].label}}</span>
        <mat-icon>keyboard_arrow_down</mat-icon>
    </button>
    <button *ngIf="items.views" (click)="onTapView('global')" [class.active]="views.isGlobalView" mat-icon-button color="primary"
            matTooltip="Vue globale affaire" matTooltipPosition="above" class="ml-3">
        <mat-icon>content_paste</mat-icon>
    </button>
    <button *ngIf="items.views" (click)="onTapView('etape')" [class.active]="views.isEtapeView" mat-icon-button color="primary"
            matTooltip="Vue étapes affaire" matTooltipPosition="above" class="ml-2">
        <mat-icon>view_week</mat-icon>
    </button>
    <button *ngIf="items.views" disabled (click)="onTapView('calendar')" [class.active]="views.isCalendarView" mat-icon-button color="primary"
            matTooltip="Vue calendrier" matTooltipPosition="above" class="ml-2">
        <mat-icon>event</mat-icon>
    </button>
    <button *ngIf="!hasRoles(['ROLE_GUEST']) && items.equipe" (click)="onTapTeamAffair()" mat-stroked-button color="primary" class="btn-rounded ml-2">
        <mat-icon>groups</mat-icon>
        <span class="d-none d-lg-inline-block ml-1">Équipe affaire</span>
    </button>
    <button *ngIf="!hasRoles(['ROLE_GUEST']) && items.collaboratif" disabled mat-stroked-button color="primary" class="btn-rounded ml-3">
        <mat-icon>people</mat-icon>
        <span class="d-none d-lg-inline-block ml-1">Espace collaboratif</span>
    </button>

    <mat-menu #stateMenu="matMenu" xPosition="before">
        <ng-template matMenuContent let-dossier="dossier">
            <button *ngIf="dossier.state == 2 || dossier.state == 3" (click)="onTapDo(dossier)" mat-menu-item>
                <mat-icon class="text-danger">undo</mat-icon>
                <span>À faire</span>
            </button>
            <button *ngIf="dossier.state == 1 || dossier.state == 3" (click)="onTapWork(dossier)" mat-menu-item>
                <mat-icon class="text-warning">{{ dossier.state == 1 ? 'redo' : 'undo'}}</mat-icon>
                <span>En cours</span>
            </button>
            <button *ngIf="dossier.state == 1 || dossier.state == 2" (click)="onTapValid(dossier)" mat-menu-item>
                <mat-icon class="text-success">redo</mat-icon>
                <span>Validé</span>
            </button>
        </ng-template>
    </mat-menu>
</div>

<mat-form-field *ngIf="!hasRoles(['ROLE_GUEST'])" (click)="navigateToSearchPage()"
                appearance="outline" class="mat-input-search pb-0 align-self-end ml-3">
    <mat-label>Saisir ma recherche</mat-label>
    <span matPrefix><mat-icon>search</mat-icon></span>
    <input matInput>
</mat-form-field>
