import {User} from '@src/app/model/user.model';
import {Affair} from '@src/app/model/affair.model';
import {Dossier} from '@src/app/model/dossier.model';
import {Society} from '@src/app/model/society.model';

export class Trade {
    id?: number;
    label?: string;
    contacts?: User[];
    affairs?: Affair[];
    dossiers?: Dossier[];
    societies?: Society[];
    dateCreated?: string;
    dateUpdated?: string;

    constructor(
        id?: number,
        label?: string
    ) {
        this.id = id || null;
        this.label = label || '';
    }
}
