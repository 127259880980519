export class Society {
    id: number;
    name: string;
    alternativeName: string;
    type: any;
    code: string;
    address: string;
    postalCode: string;
    city: string;
    country: string;
    email: string;
    web: string;
    phone: string;
    siren: string;
    tva: boolean;
    tvaNumber: string;
    thirdParty: any;
    legalEntity: any;
    capital: string;
    contacts: [
        string
    ];
    dossiers: [
        string
    ];
    solicitedDossiers: [
        string
    ];
    externalType: string;
    externalObject: number;
    dateCreated: string;
    dateUpdated: string;
    trades: any[];
    affairs: [
        string
    ];
    specificDossiers: [
        string
    ];

    constructor(
        name?: string,
        code?: string,
        alternativeName?: string,
        type?: any,
        address?: string,
        postalCode?: string,
        city?: string,
        country?: string,
        email?: string,
        web?: string,
        phone?: string,
        siren?: string,
        tva?: boolean,
        tvaNumber?: string,
        thirdParty?: any,
        legalEntity?: any,
        capital?: string,
        trades?: any[],
    ) {
        this.name = name || '';
        this.code = code || '';
        this.alternativeName = alternativeName || '';
        this.type = type || null;
        this.address = address || '';
        this.postalCode = postalCode || '';
        this.city = city || '';
        this.country = country || '';
        this.email = email || '';
        this.web = web || '';
        this.phone = phone || '';
        this.siren = siren || '';
        this.tva = tva || false;
        this.tvaNumber = tvaNumber || '';
        this.thirdParty = thirdParty || null;
        this.legalEntity = legalEntity || null;
        this.capital = capital || '';
        this.trades = trades || [];
    }
}

export const TYPES = [
    {key: 1, label: 'Prospect'},
    {key: 2, label: 'Client'},
    {key: 3, label: 'Fournisseur'},
];

export const THIRD_PARTIES = [
    {key: 5, label: 'Administration'},
    {key: 100, label: 'Autre'},
    {key: 2, label: 'Grand compte'},
    {key: 3, label: 'PME/PMI'},
    {key: 8, label: 'TPE'},
    {key: 4, label: 'Particulier'},
];

export const ENTITIES = [
    {key: 71, label: 'Administration de l\'état'},
    {key: 17, label: 'Agent commercial'},
    {key: 13, label: 'Artisan (EI)'},
    {key: 11, label: 'Artisan Commerçant (EI)'},
    {key: 92, label: 'Association loi 1901 ou assimilé'},
    {key: 18, label: 'Associé Gérant de société'},
    {key: 61, label: 'Caisse d\'épargne et de prévoyance'},
    {key: 72, label: 'Collectivité territoriale'},
    {key: 83, label: 'Comité d\'entreprise'},
    {key: 12, label: 'Commerçant (EI)'},
    {key: 60, label: 'Entreprise Individuelle à Responsabilité Limitée (EIRL)'},
    {key: 58, label: 'Entreprise Unipersonnelle à Responsabilité Limitée (EURL)'},
    {key: 73, label: 'Etablissement public administratif'},
    {key: 41, label: 'Etablissement public ou régie à caractère industriel ou commercial'},
    {key: 16, label: 'Exploitant agricole'},
    {key: 93, label: 'Fondation'},
    {key: 62, label: 'Groupement d\'intérêt économique (GIE)'},
    {key: 29, label: 'Groupement de droit privé non doté de la personnalité morale'},
    {key: 21, label: 'Indivision'},
    {key: 14, label: 'Officier public ou ministériel'},
    {key: 85, label: 'Organisme de retraite à adhésion non obligatoire'},
    {key: 81, label: 'Organisme gérant régime de protection social à adhésion obligatoire'},
    {key: 82, label: 'Organisme mutualiste'},
    {key: 84, label: 'Organisme professionnel'},
    {key: 27, label: 'Paroisse hors zone concordataire'},
    {key: 99, label: 'Personne morale de droit privé'},
    {key: 74, label: 'Personne morale de droit public administratif'},
    {key: 31, label: 'Personne morale de droit étranger, immatriculée au RCS'},
    {key: 32, label: 'Personne morale de droit étranger, non immatriculée au RCS'},
    {key: 19, label: 'Personne physique'},
    {key: 69, label: 'ersonnes de droit privé inscrites au RCS'},
    {key: 15, label: 'Profession libérale (EI)'},
    {key: 35, label: 'Régime auto-entrepreneur'},
    {key: 55, label: 'Société anonyme à conseil d administration'},
    {key: 56, label: 'Société anonyme à directoire'},
    {key: 65, label: 'Société civile'},
    {key: 63, label: 'Société coopérative agricole'},
    {key: 51, label: 'Société coopérative commerciale particulière'},
    {key: 22, label: 'Société créée de fait'},
    {key: 53, label: 'Société en commandite'},
    {key: 52, label: 'Société en nom collectif'},
    {key: 23, label: 'Société en participation'},
    {key: 64, label: 'Société non commerciale d assurances'},
    {key: 57, label: 'Société par actions simplifiée (SAS)'},
    {key: 59, label: 'Société par actions simplifiée unipersonnelle (SASU)'},
    {key: 54, label: 'Société à responsabilité limitée (SARL)'},
    {key: 91, label: 'Syndicat de propriétaires'},
];
