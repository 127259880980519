import {Component, Input, ViewContainerRef} from '@angular/core';
import {ActionBottomSheetService} from '@src/app/services/action-bottom-sheet.service';
import {AffairDossierComponent} from '@src/app/affair/affair-dossier/affair-dossier.component';
import {DocumentHistorique} from '@src/app/model/document-historique.model';
import {BaseDossierComponent} from '@src/app/shared/base-dossier.component';

@Component({
    selector: 'app-dossier',
    templateUrl: './dossier.component.html',
    styleUrls: ['./dossier.component.scss'],
    providers: [ActionBottomSheetService]
})
export class DossierComponent extends BaseDossierComponent {

    @Input() selectedDocumentHistorique: DocumentHistorique;
    @Input() activeStatus: number;
    spinner = {
        diffuse: false,
        valideCa: false,
        refuseCa: false,
        valideResponse: false,
    };

    constructor(
        private affairDossierComponent: AffairDossierComponent,
    ) {
        super();
    }

    // TODO a passer en EventEmitter
    documentClickedHandler(args): void {
        this.affairDossierComponent.documentClickedHandler(args);
    }

    // TODO a passer en EventEmitter
    activityAddedHandler(args): void {
        this.affairDossierComponent.activityAddedHandler(args);
    }

    // onTapDo(): void {
    //     this.dossierService.state(this.dossier, 1)
    //         .pipe(takeUntil(this.notifier))
    //         .subscribe((result: ActionStateResult) => {
    //             if (result.success) { this.dossier.state = 1; }
    //         });
    // }
    //
    // onTapWork(): void {
    //     this.dossierService.state(this.dossier, 2)
    //         .pipe(takeUntil(this.notifier))
    //         .subscribe((result: ActionStateResult) => {
    //             if (result.success) { this.dossier.state = 2; }
    //         });
    // }
    //
    // onTapValid(): void {
    //     this.dossierService.state(this.dossier, 3)
    //         .pipe(takeUntil(this.notifier))
    //         .subscribe((result: ActionStateResult) => {
    //             if (result.success) { this.dossier.state = 3; }
    //         });
    // }

    // onTapEdit(): void {
    //     this.dossierService.edit(this.dossier)
    //         .pipe(takeUntil(this.notifier))
    //         .subscribe((dossier: Dossier) => {
    //             if (dossier) { this.dossier = dossier; }
    //         });
    // }

    // onTapAuthorize(): void {
    //     this.dossierService.authorize(this.dossier)
    //         .pipe(takeUntil(this.notifier))
    //         .subscribe((dossier: Dossier) => {
    //             if (dossier) { this.dossier = dossier; }
    //         });
    // }

    // onTapDelete(): void {
    //     this.dossierService.delete(this.dossier)
    //         .pipe(takeUntil(this.notifier))
    //         .subscribe((result: ActionResult) => {
    //             if (result.success) { this.dossierDeleted.emit(this.dossier); }
    //         });
    // }

    // onTapMore(type: string): void {
    //     // TODO Typer les retours
    //     this.dossierService.more(this.dossier, type)
    //         .pipe(takeUntil(this.notifier))
    //         .subscribe(result => {
    //             if (result && result.action) {
    //                 setTimeout(() => { this[result.action](); }, 300);
    //             }
    //         });
    // }

    // TODO On peux utilisé onTapEditDossier partout, mais refaire le cheminement de modal coté appli
    // onTapEditDossierModal(dossier: Dossier): void {
    //     this.modalService.show(
    //         {
    //             id: dossier.id,
    //             type: 'dossier-edit',
    //         },
    //         'base-modal',
    //         this.viewContainerRef,
    //         true
    //     )
    //         .pipe(takeUntil(this.notifier))
    //         .subscribe((result) => {
    //             if (result) { this.dossier = result; }
    //         });
    // }

    // TODO On peux utilisé onTapAuthorizeDossier partout, mais refaire le cheminement de modal coté appli
    // onTapAuthorizeDossierModal(dossier: Dossier): void {
    //     this.modalService.show(
    //         {
    //             id: dossier.id,
    //             type: 'dossier-societies',
    //         },
    //         'base-modal',
    //         this.viewContainerRef,
    //         true
    //     )
    //         .pipe(takeUntil(this.notifier))
    //         .subscribe((result) => {
    //             if (result) {
    //                 this.dossier = result;
    //             }
    //         });
    // }
}
