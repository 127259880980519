import {NgModule} from '@angular/core';
import {RouterModule} from '@angular/router';
import {routes} from '@src/app/app.routes';
import {AffairFormComponent} from '@src/app/affair/form/affair-form.component';
import {IsSignedInGuard} from '@src/app/shared/is-signed-in.guard';
import {AnnuaireSocietyFormComponent} from '@src/app/annuaire/society/form/annuaire-society-form.component';
import {AnnuaireTradeFormComponent} from '@src/app/annuaire/trade/form/annuaire-trade-form.component';
import {AnnuaireUserFormComponent} from '@src/app/annuaire/user/form/annuaire-user-form.component';
import {ModalAuthorizeComponent} from '@src/app/affair/form/modal-authorize.component';
import {UserModalViewContentComponent} from '@src/app/form/user-modal-view-content.component';
import {GuestModalViewContentComponent} from '@src/app/form/guest-modal-view-content.component';

routes.push({
    path: 'affair-form',
    component: AffairFormComponent,
    canActivate: [IsSignedInGuard],
});
routes.push({
    path: 'affair-form/:id',
    component: AffairFormComponent,
    canActivate: [IsSignedInGuard],
});
routes.push({
    path: 'annuaire/society/edit',
    component: AnnuaireSocietyFormComponent,
    canActivate: [IsSignedInGuard],
});
routes.push({
    path: 'annuaire/society/edit/:id',
    component: AnnuaireSocietyFormComponent,
    canActivate: [IsSignedInGuard],
});
routes.push({
    path: 'annuaire/trade/edit',
    component: AnnuaireTradeFormComponent,
    canActivate: [IsSignedInGuard],
});
routes.push({
    path: 'annuaire/trade/edit/:id',
    component: AnnuaireTradeFormComponent,
    canActivate: [IsSignedInGuard],
});
routes.push({
    path: 'annuaire/user/edit',
    component: AnnuaireUserFormComponent,
    canActivate: [IsSignedInGuard],
});
routes.push({
    path: 'annuaire/user/edit/:id',
    component: AnnuaireUserFormComponent,
    canActivate: [IsSignedInGuard],
});
// routes.push({
//     path: 'dossier-societies-form-modal/:id',
//     component: ModalAuthorizeComponent,
//     outlet: 'modalContent',
//     canActivate: [IsSignedInGuard],
// });
// routes.push({
//     path: 'user-form-modal',
//     component: UserModalViewContentComponent,
//     outlet: 'modalContent',
//     canActivate: [IsSignedInGuard],
// });
// routes.push({
//     path: 'user-form-modal/:id',
//     component: UserModalViewContentComponent,
//     outlet: 'modalContent',
//     canActivate: [IsSignedInGuard],
// });
// routes.push({
//     path: 'guest-form-modal',
//     component: GuestModalViewContentComponent,
//     outlet: 'modalContent',
//     canActivate: [IsSignedInGuard],
// });

@NgModule({
    imports: [RouterModule.forRoot(routes, {onSameUrlNavigation: 'reload'})],
    exports: [RouterModule]
})
export class AppRoutingModule {
}
