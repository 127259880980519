import {Component, OnInit, ViewContainerRef} from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import {AnnuaireNavItems, AnnuaireViewsItems} from '@src/app/shared/interfaces';
import { AlertService } from '../services/alert.service';
import { ApiService } from '../services/api.service';
import { DossierService } from '../services/dossier.service';
import { ModalService } from '../services/modal.service';
import { ToastService } from '../services/toast.service';
import {BaseComponent} from '@src/app/shared/base.component';
import {MatDrawerMode} from '@angular/material/sidenav';

@Component({
    selector: 'app-annuaire',
    templateUrl: './annuaire.component.html',
    styleUrls: ['./annuaire.component.scss'],
})
export class AnnuaireComponent extends BaseComponent implements OnInit {
    title = 'Annuaires';
    mode: MatDrawerMode = 'over';
    backdrop = true;
    isBusy: boolean;
    activeStep: any;
    views: AnnuaireViewsItems = {
        isSociety: false,
        isTrade: false,
        isUser: true,
    };
    back: any[];
        querySearch: any;
    items: AnnuaireNavItems = {
        back: false,
        state: false,
        views: true,
    };

    constructor(
        private apiService: ApiService,
        private router: Router,
        private activatedRoute: ActivatedRoute,
        private toastService: ToastService,
        private modalService: ModalService,
        private alertService: AlertService,
        public viewContainerRef: ViewContainerRef,
        private dossierService: DossierService,
    ) {
        super();
        this.sharedService.updateTitle(this.title);
        this.sharedService.updateMode(this.mode);
        this.sharedService.updateBackdrop(this.backdrop);
    }

    ngOnInit() {
        // this.isBusy = true;
        this.items.back = !this.hasRoles(['ROLE_GUEST']);
    }

    viewHandler(views: AnnuaireViewsItems): void {
        this.views = views;
    }
}
