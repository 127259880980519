import {Injectable, ViewContainerRef} from '@angular/core';
import {MatDialog, MatDialogConfig, MatDialogRef} from '@angular/material/dialog';
import {ModalDossierComponent} from '@src/app/affair/form/modal-dossier.component';
import {ModalAuthorizeComponent} from '@src/app/affair/form/modal-authorize.component';
import {ModalShareComponent} from '@src/app/affair/form/modal-share.component';
import {ViewerComponent} from '@src/app/affair/affair-dossier/viewer/viewer.component';
import {ModalRenameComponent} from '@src/app/affair/form/modal-rename.component';
import {BaseModalViewComponent} from '@src/app/form/base-modal-view.component';
import {ModalActivityComponent} from '@src/app/affair/affair-dossier/activity/modal-activity.component';
import {ModalFileComponent} from '@src/app/affair/form/modal-file-component';
import {ModalShortcutComponent} from '@src/app/affair/form/modal-shortcut-component';
import {ModalFavoriComponent} from '@src/app/affair/form/modal-favori-component';
import {GuestModalViewContentComponent} from '@src/app/form/guest-modal-view-content.component';
import {UserModalViewContentComponent} from '@src/app/form/user-modal-view-content.component';
import {Observable} from 'rxjs';
import { ModalTeamAffairComponent } from '../affair/form/modal-team-affair.component';
import {ModalDateLimiteComponent} from '@src/app/affair/form/modal-date-limite.component';
import {ModalDiffuseComponent} from '@src/app/affair/form/modal-diffuse.component';

@Injectable({
    providedIn: 'root'
})
export class ModalService {

    constructor(public dialog: MatDialog) {}

    show(data: any, type: string, vcRef: ViewContainerRef, fullscreen: boolean = false): Observable<any> {
        const config: MatDialogConfig = {
            minWidth: '320px',
            maxWidth: '90vw',
            data: data,
            panelClass: fullscreen ? 'full-screen-dialog' : '',
            disableClose: true,
            viewContainerRef: vcRef,
        };

        let dialogRef: MatDialogRef<any>;
        switch (type) {
            case 'dossier':
                dialogRef = this.dialog.open(ModalDossierComponent, config);
                break;
            // case 'user':
            //     dialogRef = this.dialog.open(ModalUserComponent, config);
            //     break;
            case 'user':
                dialogRef = this.dialog.open(UserModalViewContentComponent, config);
                break;
            case 'authorize':
                dialogRef = this.dialog.open(ModalAuthorizeComponent, config);
                break;
            case 'guest':
                dialogRef = this.dialog.open(GuestModalViewContentComponent, config);
                break;
            case 'share':
                dialogRef = this.dialog.open(ModalShareComponent, config);
                break;
            case 'rename':
                dialogRef = this.dialog.open(ModalRenameComponent, config);
                break;
            case 'viewer':
                dialogRef = this.dialog.open(ViewerComponent, config);
                break;
            case 'activity':
                dialogRef = this.dialog.open(ModalActivityComponent, config);
                break;
            case 'base-modal':
                dialogRef = this.dialog.open(BaseModalViewComponent, config);
                break;
            case 'dossier-file':
                dialogRef = this.dialog.open(ModalFileComponent, config);
                break;
            case 'dossier-shortcut':
                dialogRef = this.dialog.open(ModalShortcutComponent, config);
                break;
            case 'dossier-favori':
                dialogRef = this.dialog.open(ModalFavoriComponent, config);
                break;
            case 'dossier-date-limite':
                dialogRef = this.dialog.open(ModalDateLimiteComponent, config);
                break;
            case 'team-affair':
                dialogRef = this.dialog.open(ModalTeamAffairComponent, config);
                break;
            case 'diffuse':
                dialogRef = this.dialog.open(ModalDiffuseComponent, config);
                break;
        }

        return dialogRef.afterClosed();
    }
}
