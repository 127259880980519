<div class="search">
    <div fxLayout="row">
        <h1>
            <mat-icon (click)="goBack()">arrow_back</mat-icon>
            Recherche
        </h1>
    </div>

    <div fxLayout="row" fxLayoutAlign="center start">
        <div fxFlex="60" fxLayout="column">
            <mat-form-field appearance="outline" class="mat-input-search">
                <mat-label>Lancer la recherche</mat-label>
                <input #searchWeb matInput (keyup.enter)="searchDocument()" [(ngModel)]="searchParameters.terms">
                <mat-icon matSuffix (click)="clearSearchPhrase()">close</mat-icon>
            </mat-form-field>

            <div>
                <button class="search-category search-category--selected"
                        (click)="removeSearchCategoryFromList('affairs', filter)"
                        *ngFor="let filter of searchParameters.filters.affairs">
                    Affaire : {{ filter.label }} <i class="far fa-times-circle"></i>
                </button>

                <button class="search-category search-category--selected"
                        (click)="removeSearchCategoryFromList('personnes', filter)"
                        *ngFor="let filter of searchParameters.filters.personnes">
                    Personne : {{ filter.label }} <i class="far fa-times-circle"></i>
                </button>

                <button class="search-category search-category--selected"
                        (click)="removeSearchCategoryFromList('steps', filter)"
                        *ngFor="let filter of searchParameters.filters.steps">
                    Étape : {{ filter.label }} <i class="far fa-times-circle"></i>
                </button>

                <button class="search-category search-category--selected"
                        (click)="searchParameters.filters.after = null"
                        *ngIf="searchParameters.filters.after">
                    Après le : {{ searchParameters.filters.after | date:'dd/MM/yyyy' }} <i
                        class="far fa-times-circle"></i>
                </button>

                <button class="search-category search-category--selected"
                        (click)="searchParameters.filters.before = null"
                        *ngIf="searchParameters.filters.before">
                    Avant le : {{ searchParameters.filters.before | date:'dd/MM/yyyy' }} <i
                        class="far fa-times-circle"></i>
                </button>

                <button class="search-category search-category--selected"
                        (click)="removeSearchCategoryFromList('dirids', filter, false)"
                        *ngFor="let filter of searchParameters.filters.dirids">
                    Dossier : {{ filter.label }} <i class="far fa-times-circle"></i>
                </button>
            </div>

            <div>
                <mat-form-field class="select-dropdown" appearance="outline">
                    <mat-label>Affaires</mat-label>
                    <mat-select (selectionChange)="onSelectionChange($event, 'affairs')">
                        <mat-option *ngFor="let filter of documentsFiltersList?.affairs" [value]="filter">
                            {{filter.label}}
                        </mat-option>
                    </mat-select>
                </mat-form-field>

                <mat-form-field class="select-dropdown" appearance="outline">
                    <mat-label>Personnes</mat-label>
                    <mat-select (selectionChange)="onSelectionChange($event, 'personnes')">
                        <mat-option *ngFor="let filter of documentsFiltersList?.personnes" [value]="filter">
                            {{filter.label}}
                        </mat-option>
                    </mat-select>
                </mat-form-field>

                <mat-form-field class="select-dropdown" appearance="outline">
                    <mat-label>Étapes</mat-label>
                    <mat-select (selectionChange)="onSelectionChange($event, 'steps')">
                        <mat-option *ngFor="let filter of documentsFiltersList?.steps" [value]="filter">
                            {{filter.label}}
                        </mat-option>
                    </mat-select>
                </mat-form-field>

                <div class="checkbox-dropdown" (click)="pickerAfter.open()"
                     [style.visibility]="searchParameters.filters.date? 'hidden': 'visible'">
                    <button class="search-category">
                        Début <i class="fas" [ngClass]="showDropdown[3] ? 'fa-caret-up' : 'fa-caret-down'"></i>
                    </button>
                    <mat-datepicker touchUi #pickerAfter></mat-datepicker>
                    <input matInput [matDatepicker]="pickerAfter" class="d-none"
                           (dateInput)="addDateEvent($event, 'after')">
                </div>

                <div class="checkbox-dropdown" (click)="pickerBefore.open()"
                     [style.visibility]="searchParameters.filters.date? 'hidden': 'visible'">
                    <button class="search-category">
                        Fin <i class="fas" [ngClass]="showDropdown[3] ? 'fa-caret-up' : 'fa-caret-down'"></i>
                    </button>
                    <mat-datepicker touchUi #pickerBefore></mat-datepicker>
                    <input matInput [matDatepicker]="pickerBefore" class="d-none"
                           (dateInput)="addDateEvent($event, 'before')">
                </div>
            </div>
        </div>

        <button mat-stroked-button type="submit" class="search-button" (click)="searchDocument()">
            <mat-icon>search</mat-icon>
            Rechercher
        </button>
    </div>
</div>

<div class="container-results" *ngIf="documentList$" fxLayout="column wrap">
    <div class="search-result d-flex flex-row flex-wrap" *ngFor="let document of documentList$">
        <h3 class="text-break cursor-pointer w-100 d-flex align-items-center">
            <a *ngIf="!document?.specificDetail" [routerLink]="['/affair-dossier', document.dossier?.id, document.id]" [queryParams]="{_d: document.id}">{{ document.name }}</a>
            <a *ngIf="document?.specificDetail && !document.specificDetail?.user" [routerLink]="['/specific-detail', document.specificDetail?.version.id]" [queryParams]="{_d: document.id}">{{ document.name }}</a>
            <a *ngIf="document?.specificDetail && document.specificDetail?.user" [routerLink]="['/specific-detail', document.specificDetail?.version.id, document.specificDetail?.user.id]" [queryParams]="{_d: document.id}">{{ document.name }}</a>
        </h3>
        <div class="search-result-info">
            <div class="search-result-image" fxFlex="50" fxLayoutAlign="start center">
                <img class="search-result__image cursor-pointer" *ngIf="document.thumbnailLink" [src]="document.thumbnailLink" [alt]="document.name" (click)="onTapViewer(document)">
                <span class="search-result__image fz-50 fas" *ngIf="!document.thumbnailLink" [class]="document.icon"></span>
            </div>
            <div class="search-result-text" fxFlex="50">
                <p>Auteur : {{ document.user.fullName }}</p>
                <p>Date d'ajout : {{ document.dateCreated | date:'dd/MM/yyyy' }}</p>
                <p>
                    <span *ngIf="document.affair">Présent dans l'affaire
                        <a class="link-affair cursor-pointer" (click)="goToStep(document.affair.id)">{{ document.affair.label }}</a>,
                    </span>
                    <span *ngIf="document.step">à l'étape
                        <a class="link-step cursor-pointer" [routerLink]="['/affaire', document.affair.id]" [queryParams]="{_s: (document.step.id - 1)}">{{ document.step.label }}</a>
                    </span>
                </p>
                <p class="highlight" [innerHTML]="highlight" *ngFor="let highlight of document.highlight "></p>
            </div>
        </div>
    </div>
    <div *ngIf="documentList$.length === 0" fxFlex="100" class="text-center text-red p-3">
        Aucune réponse n'a été trouvée, merci d'élargir votre recherche.
    </div>
</div>
