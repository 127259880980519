import {Component, Input, ViewEncapsulation} from '@angular/core';
import {CalendarDateFormatter, CalendarEvent, CalendarView, DAYS_OF_WEEK} from 'angular-calendar';
import {CustomDateFormatter} from '@src/app/home/calendar/CustomDateFormatter/CustomDateFormatter';

@Component({
    selector: 'app-calendar',
    templateUrl: 'calendar.component.html',
    styleUrls: ['calendar.component.scss'],
    encapsulation: ViewEncapsulation.None,
    providers: [{
        provide: CalendarDateFormatter,
        useClass: CustomDateFormatter
    }]
})

export class CalendarComponent {
    @Input() today: Date;
    @Input() events: CalendarEvent[] = [];
    weekStartsOn = DAYS_OF_WEEK.MONDAY;
    view = CalendarView.Month;
}
